import { Typography } from "@mui/material";
import { styled } from "@mui/system";

export const ExampleText = styled(Typography)(() => ({
  cursor: "pointer",
  transition: "all 0.2s",
  "&:hover": {
    color: "#37d2bb",
    transition: "all 0.2s",
  },
  marginLeft: "30px",
}));
