import {
  getDatasets,
  sendDatasets,
  updateDatasetConfiguration,
  createDataset,
  getDatasetData,
  getDatasetSummary,
  // getDatasetFrequency,
  updateVariable,
  getFrequencyByNames,
  getDatasetNames,
  deleteDataset,
} from "../services/datasets";
import { setLoading } from "./user";

export const SET_DATASETS = "DATASETS/SET_DATASETS";
export const SET_DATASET_NAMES = "DATASETS/SET_DATASET_NAMES";
export const CHANGE_FILTERS = "DATASETS/CHANGE_FILTERS";
export const OPEN_CREATE_DIALOG = "DATASETS/OPEN_CREATE_DIALOG";
export const OPEN_SETTINGS_DIALOG = "DATASETS/OPEN_SETTINGS_DIALOG";
export const SET_DATASET_FILE = "DATASETS/SET_DATASET_FILE";
export const HANDLE_CHANGE_CONFIGURATION =
  "DATASETS/HANDLE_CHANGE_CONFIGURATION";
export const HANDLE_CHANGE_VAR_TYPES = "DATASETS/HANDLE_CHANGE_VAR_TYPES";
export const SET_DATA = "DATASETS/SET_DATA";
export const SET_SELECTED_VARIBLES = "DATASETS/SET_SELECTED_VARIBLES";
export const SET_ERRORS = "DATASET/SET_ERRORS";
export const CLEAR_FILTERS = "DATASET/CLEAR_FILTERS";

export const fetchDatasets = () => {
  return (dispatch, getState) => {
    getDatasets(getState().datasetsReducer.filters).then((res) => {
      dispatch({
        type: SET_DATASETS,
        list: res.data.records || [],
        count: res.data.count,
      });
    });
  };
};

export const fetchDatasetNames = () => {
  return (dispatch) => {
    getDatasetNames().then((res) => {
      dispatch({
        type: SET_DATASET_NAMES,
        namesList: res.data.records || [],
      });
    });
  };
};

export const changeFilters = (filters) => {
  return (dispatch) => {
    Promise.resolve(dispatch({ type: CHANGE_FILTERS, filters })).then(() =>
      dispatch(fetchDatasets())
    );
  };
};

export const clearFilters = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_FILTERS });
  };
};

export const openCreateDialog = (open) => {
  return (dispatch) => {
    dispatch({ type: OPEN_CREATE_DIALOG, open });
  };
};

export const openSettingsDialog = (open) => {
  return (dispatch) => {
    dispatch({ type: OPEN_SETTINGS_DIALOG, open });
  };
};

export const uploadDatasets = (params) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    sendDatasets(params).then((res) => {
      dispatch({ type: SET_DATASET_FILE, dataSetFile: res.data });
      dispatch(openCreateDialog(false));
      dispatch(openSettingsDialog(true));
      dispatch(setLoading(false));
    });
  };
};

export const handleChangeConfiguration = (configuration) => {
  return (dispatch) => {
    Promise.resolve(
      dispatch({ type: HANDLE_CHANGE_CONFIGURATION, configuration })
    ).then(() => dispatch(sendDatasetConfiguration()));
  };
};

export const handleChangeVarTypes = (varTypes) => {
  return (dispatch) => {
    dispatch({ type: HANDLE_CHANGE_VAR_TYPES, varTypes });
  };
};

export const sendDatasetConfiguration = () => {
  return (dispatch, getState) => {
    const unique_id = getState().datasetsReducer.dataSetFile.unique_id;
    const configuration = getState().datasetsReducer.datasetCnfiguration;
    updateDatasetConfiguration(unique_id, configuration).then((res) => {
      dispatch({ type: SET_DATASET_FILE, dataSetFile: res.data });
    });
  };
};

export const saveDataset = (navigate) => {
  return (dispatch, getState) => {
    dispatch(setLoading(true));
    const unique_id = getState().datasetsReducer.dataSetFile.unique_id;
    const configuration = getState().datasetsReducer.datasetCnfiguration;
    const variable_types = getState().datasetsReducer.datasetVarTypes;
    createDataset(unique_id, configuration, variable_types).then((res) => {
      dispatch(openSettingsDialog(false));
      dispatch(fetchDatasets());
      dispatch(setLoading(false));
      navigate(`/dashboard/project/${res.data.project_id}`);
    });
  };
};

export const fetchFrequencyByNames = (id, firstFrequencyName, callback) => {
  return (dispatch, getState) => {
    const frequenchState = getState().datasetsReducer.frequency;
    getFrequencyByNames(id, [firstFrequencyName]).then(({ data }) => {
      callback && callback();
      const headers = Object.keys(data.frequency_tables);
      headers.forEach((table_name) => {
        if (Array.isArray(data.frequency_tables[table_name])) {
          return;
        }
        let arr = [];
        const table_headers = Object.keys(data.frequency_tables[table_name]);
        const length = Object.keys(
          data.frequency_tables[table_name][table_headers[0]]
        ).length;
        for (let i = 0; i < length; i++) {
          let item = {};
          table_headers.forEach((header) => {
            item[header] = data.frequency_tables[table_name][header][i];
          });
          arr.push(item);
        }
        data.frequency_tables[table_name] = arr;
      });
      dispatch({
        type: SET_DATA,
        frequency: { ...frequenchState, ...data.frequency_tables },
      });
    });
  };
};

export const fetchDataset = (id, filters = {}, fetchFirstFrequency = false) => {
  return (dispatch) => {
    getDatasetData(id, filters).then((res) => {
      const { offset = 0, limit = 25 } = filters;
      let varTypes = res.data.variable_names.replaceAll("'", '"');
      varTypes = JSON.parse(varTypes);
      let arr = [];
      let headers = Object.keys(res.data.data);
      for (let i = offset; i < limit + offset; i++) {
        let item = {};
        headers.forEach((header) => {
          item[header] = res.data.data[header][i];
        });
        if (res.data.number_of_rows >= i) arr.push(item);
      }
      dispatch({
        type: SET_DATA,
        data: arr,
        count: res.data.number_of_rows,
      });
      dispatch({ type: HANDLE_CHANGE_VAR_TYPES, varTypes });
      if (fetchFirstFrequency)
        dispatch(fetchFrequencyByNames(id, Object.keys(varTypes)[0]));
    });
  };
};

export const fetchSummary = (id) => {
  return (dispatch) => {
    getDatasetSummary(id)
      .then((res) => {
        let data = {};
        const headres = Object.keys(res.data.summary_tables);
        const variable_names = Object.keys(res.data.summary_tables[headres[0]]);
        variable_names.forEach((variable_name) => {
          let item = {};
          headres.forEach((header) => {
            item.name = [variable_name];
            item[header] = [res.data.summary_tables[header][variable_name]];
          });
          data[variable_name] = item;
        });
        dispatch({
          type: SET_DATA,
          summary: data,
        });
      })
      .finally(() => dispatch(setLoading(false)));
  };
};

// export const fetchFrequency = (id) => {
//   return (dispatch) => {
//     getDatasetFrequency(id).then((res) => {
//       const headers = Object.keys(res.data.frequency_tables);
//       headers.forEach((table_name) => {
//         if (Array.isArray(res.data.frequency_tables[table_name])) {
//           return;
//         }
//         let arr = [];
//         const table_headers = Object.keys(
//           res.data.frequency_tables[table_name]
//         );
//         const length = Object.keys(
//           res.data.frequency_tables[table_name][table_headers[0]]
//         ).length;
//         for (let i = 0; i < length; i++) {
//           let item = {};
//           table_headers.forEach((header) => {
//             item[header] = res.data.frequency_tables[table_name][header][i];
//           });
//           arr.push(item);
//         }
//         res.data.frequency_tables[table_name] = arr;
//       });
//       dispatch({
//         type: SET_DATA,
//         frequency: res.data.frequency_tables,
//       });
//     });
//   };
// };

export const setSelectedVaribles = (varibles) => {
  return (dispatch) => {
    dispatch({ type: SET_SELECTED_VARIBLES, varibles });
  };
};

export const clearErrors = () => {
  return (dispatch) => {
    dispatch({ type: SET_ERRORS, errors: [] });
  };
};

export const updateVariableAttribute = (data, id) => {
  return (dispatch) => {
    updateVariable(data, id).then((res) => {
      console.log(res.data);
    });
  };
};

export const deleteDatasetById = (id, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    deleteDataset(id)
      .then(() => callback())
      .finally(() => dispatch(setLoading(false)));
  };
};
