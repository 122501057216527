import { FullScreenBtn } from "../../../../../../components/buttons/full-screen-btn";
import { AnalyseGraphsContent } from "../AnalyseGraphsContent";

import {
  AnalysesWrapper,
  AccordionWrapper,
  FullScreenBtnWrapper,
} from "../AnalyseItem/styled";

import { Box } from "@mui/system";

export const AnalyseContentWrapper = ({
  table,
  callback,
  currentTab = "",
  callbackItem,
  currentAnalyse,
  callbackGraphItem,
  independent = false,
}) => {
  return (
    <AnalysesWrapper>
      <Box
        sx={{
          width: "68%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <AccordionWrapper>{table}</AccordionWrapper>
        <FullScreenBtnWrapper>
          <FullScreenBtn callback={() => callback(callbackItem)} />
        </FullScreenBtnWrapper>
      </Box>
      <Box sx={{ width: "30%", display: "flex", flexDirection: "column" }}>
        <AnalyseGraphsContent
          independent={independent}
          currentTab={currentTab}
          data={currentAnalyse?.summary?.images}
          analysisType={currentAnalyse.analysis_type}
        />
        <FullScreenBtnWrapper>
          <FullScreenBtn callback={() => callback(callbackGraphItem)} />
        </FullScreenBtnWrapper>
      </Box>
    </AnalysesWrapper>
  );
};
