import { useEffect, useState } from "react";

import { AnalyseTable } from "../AnalyseTable";

import { AccordionContent } from "../AnalyseItem/styled";

import { Accordion, AccordionDetails, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const MultivariateAccordion = ({ data }) => {
  const [keys, setKeys] = useState([]);

  useEffect(() => {
    setKeys(Object.keys(data));
  }, [data]);

  return keys.map((key, index) => (
    <Accordion key={index} defaultExpanded={!index} sx={{ boxShadow: "none" }}>
      <AccordionContent expandIcon={<ExpandMoreIcon />}>
        <Typography lineHeight={2.75}>
          <b>{key}</b>
        </Typography>
      </AccordionContent>
      <AccordionDetails sx={{ paddingRight: "0px 2px 0px 0px" }}>
        <AnalyseTable data={data[key] || []} />
      </AccordionDetails>
    </Accordion>
  ));
};
