import { FullScreenIcon } from "../../Icons/FullScreenIcon";

import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const FullScreenBtnContainer = styled(Box)(() => ({
  cursor: "pointer",
  padding: "8px 8px 5px 8px",
  borderRadius: "8px",
  border: "1px solid #656565",
}));

export const FullScreenBtn = ({ callback }) => {
  return (
    <FullScreenBtnContainer onClick={callback}>
      <FullScreenIcon />
    </FullScreenBtnContainer>
  );
};
