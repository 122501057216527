import { useEffect, useState } from "react";

import { AnalyseTable } from "../AnalyseTable";

import { AccordionContent } from "../AnalyseItem/styled";

import { Accordion, Typography, TableContainer, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const CorelationAcardion = ({ data }) => {
  const [keys, setKeys] = useState([]);

  useEffect(() => {
    setKeys(Object.keys(data));
  }, [data]);

  return (
    !!keys.length &&
    keys.map((key, index) => (
      <TableContainer key={index}>
        <Accordion defaultExpanded={!index} sx={{ boxShadow: "none" }}>
          <AccordionContent expandIcon={<ExpandMoreIcon />}>
            <Typography lineHeight={2.75}>{key}</Typography>
          </AccordionContent>
          <AccordionDetails sx={{ padding: "0px 2px" }}>
            <AnalyseTable data={data[key]} />
          </AccordionDetails>
        </Accordion>
      </TableContainer>
    ))
  );
};
