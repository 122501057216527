import { InputBase } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledInput = styled(InputBase)(() => ({
  background: "#E6EAEE",
  height: "64px",
  fontSize: "16px",
  fontFamily: "Axiforma",
  lineHeight: "24px",
  letterSpacing: "0.5px",
  textAlign: "center",
  fontWeight: 600,
  border: "double 1px transparent",
  borderRadius: "12px",
  backgroundImage: "linear-gradient(white, white), linear-gradient(140deg, #0cecc7 0%, #18b8dc 100%)",
  backgroundOrigin: "border-box",
  backgroundClip: "padding-box, border-box",
  boxSizing: "border-box",
  color: "#24242C",
  padding: "20px 15px 20px 20px",

  "& .MuiInputBase-input": {
    padding: 0,
    marginTop: "3px",

    "&::placeholder": {
      opacity: 1,
      color: "#24242C",
    },
  },

  "& svg": {
    cursor: "pointer",
  },
}));
