import { useState } from "react";
import { styled } from "@mui/material/styles";
import { GenieIcon } from "../genie-icon";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";

const FileUploaderWrapper = styled("div")(() => ({
  width: "100%",
  fontFamily: "Prompt,sans-serif",
}));

const FileName = styled("div")(() => ({
  textAlign: "center",
  fontSize: "16px",
  color: "#163B65",
  marginBottom: "15px",
}));

const FileUploaderInput = styled(Box)(({ hoveredInput, error }) => ({
  width: "100%",
  height: "115px",
  border: error ? "1px dashed #d32f2f" : "1px dashed #8593ad",
  backgroundColor: hoveredInput ? "#F8FAFF" : "inherit",
  borderRadius: "6px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "12px",
  color: "#8593ad",
}));

export function UploadFile({
  label,
  placeholder,
  value,
  onChange,
  error,
  accept = "*",
  name = "datasetFile",
}) {
  const [hoveredInput, setHovered] = useState(false);

  const preventDefault = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const dt = event.dataTransfer;
    const e = { target: { files: dt.files } };
    onChange(e);
    event.stopPropagation();
  };

  return (
    <FileUploaderWrapper>
      <FileName>{value ? `${label} ${value?.name}` : label}</FileName>
      <FileUploaderInput
        onClick={() => document.getElementById("upload_input").click()}
        onDrop={handleDrop}
        onDragEnter={() => {
          setHovered(true);
        }}
        onDragOver={preventDefault}
        onDragLeave={() => {
          setHovered(false);
        }}
        hoveredInput={hoveredInput}
        error={error}
      >
        <GenieIcon type="cloud-icon" iconWidth="30px" iconHeight="30px" />
        <br />
        {value ? `Uploaded ${value?.name}` : placeholder}
        <input
          name={name}
          id="upload_input"
          type="file"
          accept={accept}
          hidden
          onChange={onChange}
        />
      </FileUploaderInput>
      {!!error ? (
        <Typography variant="error">{error}</Typography>
      ) : (
        <div style={{ height: "17px" }}></div>
      )}
    </FileUploaderWrapper>
  );
}
