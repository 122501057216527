import { useEffect, useState, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Grid,
  Typography,
  Backdrop,
  CircularProgress
} from '@mui/material';
import { styled } from '@mui/system';
import { GenieIcon } from '../../components/genie-icon';
import { exportTables, clearExportFileUrl } from '../../actions/analyses';

const fileTypes = [
  {name: 'PDF', icon: 'pdf', value: 'pdf'},
  {name: 'PPT', icon: 'ppt', value: 'PP'},
  {name: 'DOCX', icon: 'doc', value: 'docx'},
  {name: 'XLSX', icon: 'exel', value: 'xlsx'}
];

const FileTypeSelection = styled(Grid)(
  ({selected}) => ({
    width: '77px',
    height: '84px',
    border: selected ? '1px solid #2984FF' :'1px solid rgba(0, 0, 0, 0.1)',
    borderRadius: '6px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    margin: '0 7px 7px 0',
    cursor: 'pointer'
  })
)

export function ExportTables({activeTab}) {
  const [selectedFileType, setSelectedFileType] = useState('pdf');
  const [open, handleClose] = useState(false);
  const [openLoader, setOpenLoader] = useState(false);
  const dispatch = useDispatch();
  const { id } = useParams();
  const exportFileUrl = useSelector(state => state.analysesReducer.exportFileUrl);
  const selectedVaribles = useSelector(state => state.datasetsReducer.selectedVaribles);

  useEffect(() => {
    dispatch(clearExportFileUrl());
  }, [selectedFileType])

  const handleExport = () => {
    setOpenLoader(true)
    const data = {
      object_id: +id,
      mode: 'main_independents',
      file_type: selectedFileType,
      file_name: 'test',
      primary_names: selectedVaribles,
      secondary_names: [],
      type: activeTab === 'Frequency' ? 'frequency' : 'summary',
      split_levels: []
    }
    dispatch(exportTables(data, setOpenLoader));
  }

  const downloadFile  = () => {
    const link = document.createElement('a');
    link.href = exportFileUrl;
    link.setAttribute('download', `analyse${id}`);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  }

  const closeDialog = () => {
    handleClose(false);
    dispatch(clearExportFileUrl());
  }

  return (
    <Fragment>
      <Button 
        sx={{background: '#F2994A'}} 
        variant="contained" 
        size="small" 
        startIcon={<GenieIcon iconWidth='15px' iconHeight='15px' type="export-white"/>}
        onClick={() => handleClose(true)}
      >
        Export
      </Button>
      <Dialog open={open} onClose={closeDialog} maxWidth="md" fullWidth >
        <DialogTitle>
          Export Tables
        </DialogTitle>
        <DialogContent sx={{overflowY: 'visible'}}>
          <Typography fontSize={'18px'} color="#3B3946" marginBottom={'20px'}>Select File Type</Typography>
          <Grid container >
            {fileTypes.map(item => (
              <FileTypeSelection
                key={item.value}
                item selected={item.value === selectedFileType}
                onClick={() => setSelectedFileType(item.value)}
              >
                <GenieIcon type={item.icon} iconWidth="20px"/>
                <Typography fontSize={'12px'}fontWeight={300}>{item.name}</Typography>
              </FileTypeSelection>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color='info' onClick={closeDialog} >Cancel</Button>
          {exportFileUrl ? (
            <Button variant="contained" color="secondary" onClick={downloadFile} >Download</Button>
          ) : (
            <Button variant="contained" color="secondary" onClick={handleExport} >Create</Button>
          )}
        </DialogActions>
      </Dialog>
      <Backdrop sx={{ color: '#fff', zIndex: 10001, flexDirection: 'column'}} open={openLoader}>
        <CircularProgress color="inherit" />
        <Typography>Exporting Tables</Typography>
      </Backdrop>
    </Fragment>
  )
}