import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Grid,
  Typography,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/system";
import { GenieIcon } from "../../components/genie-icon";
import { exportTables, clearExportFileUrl } from "../../actions/analyses";

const fileTypes = [
  { name: "PDF", icon: "pdf", value: "pdf" },
  { name: "PPT", icon: "ppt", value: "PP" },
  { name: "DOCX", icon: "doc", value: "docx" },
  { name: "XLSX", icon: "exel", value: "xlsx" },
];

const FileTypeSelection = styled(Grid)(({ selected }) => ({
  width: "77px",
  height: "84px",
  border: selected ? "1px solid #2984FF" : "1px solid rgba(0, 0, 0, 0.1)",
  borderRadius: "6px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  margin: "0 7px 7px 0",
  cursor: "pointer",
}));

export function ExportTables({ open, handleClose, selectedTables, mode }) {
  const [selectedFileType, setSelectedFileType] = useState("pdf");
  const [selctedRoutes, setSelectedRoutes] = useState([]);
  const [openLoader, setOpenLoader] = useState(false);
  const dispatch = useDispatch();
  const { id } = useParams();
  const exportFileUrl = useSelector(
    (state) => state.analysesReducer.exportFileUrl
  );

  useEffect(() => {
    let selected = [];
    selectedTables.forEach((item) => {
      selected.push(item.split("*"));
    });
    setSelectedRoutes(selected);
  }, [selectedTables]);

  useEffect(() => {
    dispatch(clearExportFileUrl());
  }, [selectedFileType]);

  const handleExport = () => {
    setOpenLoader(true);
    let primary_names = [];
    let secondary_names = [];
    let split_level = [];
    selctedRoutes.forEach((route) => {
      split_level.push(route[1]);
      primary_names.push(route[2]);
      route[3] && secondary_names.push(route[3]);
    });
    const data = {
      analysis_id: +id,
      mode: mode,
      file_type: selectedFileType,
      file_name: "test",
      primary_names: primary_names,
      secondary_names: secondary_names,
      type:
        selctedRoutes[0][0] === "mixed_multivariate"
          ? "multivariate"
          : selctedRoutes[0][0],
      split_levels: split_level,
    };
    dispatch(exportTables(data, setOpenLoader));
  };

  const downloadFile = () => {
    const link = document.createElement("a");
    link.href = exportFileUrl;
    link.setAttribute("download", `analyse${id}`);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  const closeDialog = () => {
    handleClose();
    dispatch(clearExportFileUrl());
  };

  return (
    <Dialog open={open} onClose={closeDialog} maxWidth="md" fullWidth>
      <DialogTitle>Export Tables</DialogTitle>
      <DialogContent sx={{ overflowY: "visible" }}>
        <Typography fontSize={"18px"} color="#3B3946" marginBottom={"20px"}>
          Select File Type
        </Typography>
        <Grid container>
          {fileTypes.map((item) => (
            <FileTypeSelection
              key={item.value}
              item
              selected={item.value === selectedFileType}
              onClick={() => setSelectedFileType(item.value)}
            >
              <GenieIcon type={item.icon} iconWidth="20px" />
              <Typography fontSize={"12px"} fontWeight={300}>
                {item.name}
              </Typography>
            </FileTypeSelection>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="info" onClick={closeDialog}>
          Cancel
        </Button>
        {exportFileUrl ? (
          <Button variant="contained" color="secondary" onClick={downloadFile}>
            Download
          </Button>
        ) : (
          <Button variant="contained" color="secondary" onClick={handleExport}>
            Create
          </Button>
        )}
      </DialogActions>
      <Backdrop
        sx={{ color: "#fff", zIndex: 10001, flexDirection: "column" }}
        open={openLoader}
      >
        <CircularProgress color="inherit" />
        <Typography>Exporting Tables</Typography>
      </Backdrop>
    </Dialog>
  );
}
