import { useEffect, useState } from 'react';
import { EditGraphSelect } from '../../components/inputs/editGrapfSelect';
import { EditGraphInput } from '../../components/inputs/editGrphInput';
import { EditGraphAlignSelect } from '../../components/inputs/editGraphAlignSelect';
import { EditGraphColorPicker } from '../../components/inputs/editGraphColorPicker';
import { EditGraphSlider } from '../../components/inputs/editGraphSlider';

import {
  Button,
  Box,
  Typography,
  Grid,
  Collapse,
  Checkbox
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/system';
import { GenieIcon } from '../../components/genie-icon';

import {
  CompareGroupsConfigurationForm,
  CorrelationConfigurationForm,
  CorrelationHeatmapConfigurationForm,
  SurvivalConfigurationForm,
  MixedMultivariateConfigurationFrom
} from './constants';

const EditGraphHeader = styled(Box)(
  () => ({
    padding: '24px 34px',
    color: '#5A606B',
    fontSize: '24px',
    fontWeight: 600,
    borderBottom: '1px solid rgba(133, 147, 173, 0.26)',
    fontFamily: 'montserrat',
    display: 'flex',
    alignItems: 'center'
  })
)

const AnalysesListActions = styled(Box)(
  ()=> ({
    position: 'absolute',
    height: '20px',
    width: 'calc(100% - 68px)',
    borderTop: '1px solid #EAECF0',
    padding: '34px',
    bottom: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    background: '#fff'
  })
)

const AnalysesEditFormBlock = styled(Box)(
  () => ({
    width: 'calc(100% - 28px)',
    height: 'auto',
    padding: '14px',
    borderRadius: '12px',
    background: '#fff',
    boxShadow: '0px 15px 15px -4px rgba(0,0,0,0.14)',
    marginBottom: '15px',

  })
)

export const EditForm = ({configuration, onChange, handleSubmit, handleClose, analyseType, itHeat}) => {
  const [configurations, setConfigurations] = useState(null);

  useEffect(() => {
    switch(analyseType) {
      case 'compare_groups':
        setConfigurations(CompareGroupsConfigurationForm);
        break;

      case 'correlations':
        if(itHeat) {
          setConfigurations(CorrelationHeatmapConfigurationForm);
        } else {
          setConfigurations(CorrelationConfigurationForm);
        }
        break;
      case 'survival':
        setConfigurations(SurvivalConfigurationForm);
        break;

      case 'multivariate':
        setConfigurations(MixedMultivariateConfigurationFrom);
        break

      case 'mixed_multivariate':
        setConfigurations(MixedMultivariateConfigurationFrom);
        break

      default:
        break;
    }
  }, [analyseType])
  
  return configurations && (
    <Box backgroundColor={'#fff'} position={'relative'} height={'100vh'}>
      <EditGraphHeader>
        <GenieIcon type="chart-black" iconWidth={"24px"} iconHeight={"24px"} />
        <Typography sx={{marginLeft: '10px'}} variant='h4'>Graph Edit</Typography>
      </EditGraphHeader>
      <Box padding={'24px'} overflow={'auto'} maxHeight={'calc(100vh - 185px)'}>
        {configurations.map(configBlock => (
          <FormBlock key={configBlock.name} label={configBlock.name}>
            {configBlock.feilds.map(feild => (
              <Grid key={feild.valueName} container mb={'10px'}>
                <Grid item xs={3}>
                  <Typography fontSize={'12px'} fontWeight={400} color={"#181818"} lineHeight={'26px'}>{feild.label}</Typography>
                </Grid>
                <Grid item xs={9}>
                  {feild.type === 'text' && (
                    <EditGraphInput value={configuration[feild.valueName]} name={feild.valueName} onChange={onChange}/>
                  )}
                  {feild.type === 'select' && (
                    <EditGraphSelect value={configuration[feild.valueName]} name={feild.valueName} items={feild.items} onChange={onChange}/>
                  )}
                  {feild.type === 'slider' && (
                    <EditGraphSlider
                      value={configuration[feild.valueName]}
                      min={feild.min}
                      max={feild.max}
                      steps={feild.steps}
                      step={feild.step}
                      name={feild.valueName}
                      onChange={onChange}
                    />
                  )}
                  {feild.type === 'color' && (
                    <EditGraphColorPicker value={configuration[feild.valueName]} name={feild.valueName} onChange={(e) => onChange(e)}/>
                  )}
                  {feild.type === 'numeric' && (
                    <EditGraphInput value={configuration[feild.valueName]} name={feild.valueName} onChange={onChange} type="number"/>
                  )}
                  {feild.type === 'align' && (
                    <EditGraphAlignSelect value={configuration[feild.valueName]} name={feild.valueName} onChange={onChange}/>
                  )}
                  {feild.type === 'checkbox' && (
                    <Checkbox checked={configuration[feild.valueName]} name={feild.valueName} onChange={onChange} />
                  )}
                </Grid>
              </Grid>
            ))}
          </FormBlock>
        ))}
      </Box>
      <AnalysesListActions>
        <Button
          size='small'
          variant="contained"
          color="secondary"
          onClick={() => handleSubmit('all', true)}
          sx={{background: '#2984FF', minWidth: '100px'}}
        >
          Apply All
        </Button>
        <Button
          size='small'
          variant="contained"
          color="secondary"
          onClick={() => handleSubmit('single', true)}
          sx={{background: '#2984FF', minWidth: '100px', margin: '0 15px'}}
        >
          Apply
        </Button>
        <Button
          size='small'
          variant="contained"
          color="secondary"
          onClick={() => handleClose()}
          sx={{minWidth: '100px'}}
        >
          Cancel
        </Button>
      </AnalysesListActions>
    </Box>
  )
}

const FormBlock = ({children, label}) => {
  const [open, setOpen] = useState(true);

  return(
    <AnalysesEditFormBlock>
      <Grid container justifyContent={'space-between'} wrap={'nowrap'}>
        <Grid item>
          <Typography fontSize={'14px'} fontWeight={600} color={'#101020'}>{label}</Typography>
        </Grid>
        <Grid item>
          <ExpandMoreIcon
            onClick={() => setOpen(!open)}
            sx={{
                color: '#8593AD',
                cursor: 'pointer',
                transform: open ? 'rotate(.5turn)' : 'rotate(0turn)'
            }}
          />
        </Grid>
      </Grid>
      <Collapse in={open}>
        {children}
      </Collapse>
    </AnalysesEditFormBlock>
  )
}