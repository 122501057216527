import { Grid } from "@mui/material";
import { styled } from "@mui/system";

export const ActiveChatWrapper = styled(Grid)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-end",
  height: "calc(100% - 74px)",
  marginTop: "20px",
  gap: "18px",
}));
