import * as Yup from "yup";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Formik, Form, Field } from "formik";
import { useNavigate } from "react-router-dom";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";

import { loginRequest } from "../../actions/user";

import { GOOGLE_ID } from "../register/register.constants";
import { DEFAULT_USER_DATA, getLoginFields } from "./login.constants";

import {
  Line,
  TextInput,
  ErrorText,
  GreenSpan,
  LineWrapper,
  StyledDialog,
  StyledDialogContent,
} from "../register/styles";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useRef } from "react";
import { useEffect } from "react";

export default function LoginPopUp({
  open,
  handleClose,
  googleResponse,
  setOpenRegister,
  googleErrorResponse,
}) {
  const [userData, setUserData] = useState(DEFAULT_USER_DATA);

  const dispatch = useDispatch();
  let navigate = useNavigate();

  const handleLogin = () => {
    dispatch(loginRequest(userData, () => navigate("/genie-gpt")));
    onClose();
  };

  const onClose = () => {
    handleClose();
    setUserData(DEFAULT_USER_DATA);
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Email is not valid").required("Required field"),
    password: Yup.string()
      .required("Required field")
      .min(3, "Password must be at least 8 characters")
      .max(255, "Password cannot be longer than 255 characters"),
  });

  const handleUserInfo = (event) => {
    const { value, name } = event.target;
    setUserData({ ...userData, [name]: value });
  };

  const handleOpenSignUp = (event) => {
    event.stopPropagation();
    handleClose();
    setOpenRegister(true);
  };

  const fields = getLoginFields(userData);

  const btnRef = useRef(null);

  useEffect(() => {
    if (btnRef.current) {
      btnRef.current.style.display = "none";
    }
  }, [btnRef]);

  return (
    <StyledDialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <Formik
        initialValues={DEFAULT_USER_DATA}
        validationSchema={validationSchema}
        onSubmit={handleLogin}
      >
        {({ setFieldValue, setFieldTouched }) => (
          <Form>
            <StyledDialogContent>
              <Typography variant="h4">Login</Typography>
              <Grid container gap="28px" width="100%">
                {fields.map(({ name, label, value, type }, index) => (
                  <Field name={name} key={index}>
                    {({ meta }) => (
                      <Box sx={{ width: "100%" }}>
                        <TextInput
                          fullWidth
                          type={type}
                          name={name}
                          label={label}
                          value={value}
                          onBlur={() => setFieldTouched(name, true)}
                          error={!!meta.error && !!meta.touched}
                          onChange={(event) => {
                            handleUserInfo(event);
                            setFieldValue(name, event.target.value);
                          }}
                        />
                        <ErrorText>
                          {!!meta.error && !!meta.touched && meta.error}
                        </ErrorText>
                      </Box>
                    )}
                  </Field>
                ))}
              </Grid>

              <Grid
                container
                gap="18px"
                alignItems="center"
                flexDirection="column"
              >
                <Button type="submit" variant="contained" color="secondary">
                  Continue
                </Button>
                <Typography fontSize="14px">
                  Don't have an account?
                  <GreenSpan onClick={handleOpenSignUp}>Sign up</GreenSpan>
                </Typography>
                <LineWrapper>
                  <Line />
                  <Typography>OR</Typography>
                  <Line />
                </LineWrapper>

                <Grid sx={{ textAlign: "center" }}>
                  <GoogleOAuthProvider clientId={GOOGLE_ID}>
                    <GoogleLogin
                      size="large"
                      onSuccess={googleResponse}
                      onError={googleErrorResponse}
                      width="392"
                    />
                  </GoogleOAuthProvider>

                  <Typography fontSize="14px" mt="28px">
                    <GreenSpan>Privacy Policy</GreenSpan> |
                    <GreenSpan>Terms of conditions</GreenSpan>
                  </Typography>
                </Grid>
              </Grid>
            </StyledDialogContent>
          </Form>
        )}
      </Formik>
    </StyledDialog>
  );
}
