import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { PageTitle } from '../../components/pageTitle';
import { OwerviewStats } from './OwerviewStats';
import { OwerviewTabs } from './OwerviewTabs';

import { fetchAnalyses } from '../../actions/analyses';
import { fetchDatasets } from '../../actions/datasets';
import { fetchProjects } from '../../actions/projects';
import { Box } from '@mui/material';


export default function Owerview() {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAnalyses());
    dispatch(fetchDatasets());
    dispatch(fetchProjects());
  }, [])

  return (
    <Box>
      <PageTitle title='Overview' />
      <OwerviewStats/>
      <OwerviewTabs />
    </Box>
    
  )
}