import {
  Dialog,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";

export const SimplePopup = ({
  open,
  title,
  onClose,
  content,
  handleSubmit,
  cancelBtnText,
  submitBtnText,
}) => {
  return (
    <Dialog open={open} onClose={() => onClose(false)} maxWidth="xs" fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Typography>{content}</Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="info" onClick={() => onClose(false)}>
          {cancelBtnText}
        </Button>
        <Button variant="contained" color="secondary" onClick={handleSubmit}>
          {submitBtnText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
