export const getSettingsField = (userData) => [
  {
    type: "text",
    disabled: false,
    name: "first_name",
    label: "First Name",
    value: userData.first_name,
  },
  {
    type: "text",
    disabled: false,
    name: "last_name",
    label: "Last Name",
    value: userData.last_name,
  },
  {
    type: "text",
    disabled: false,
    name: "middle_name",
    label: "Middle Name",
    value: userData.middle_name,
  },
  {
    type: "number",
    disabled: false,
    name: "number_of_roundings",
    label: "Number Of Roundings",
    value: userData.number_of_roundings,
  },
  {
    type: "number",
    disabled: false,
    name: "significance_level",
    label: "Significance Level",
    value: userData.significance_level,
  },
  {
    type: "number",
    disabled: false,
    name: "significance_level_normality",
    label: "Significance Level Normality",
    value: userData.significance_level_normality,
  },
  {
    type: "text",
    disabled: true,
    name: "email",
    label: "Email",
    value: userData.email,
  },
];
