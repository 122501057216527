import { AccordionSummary, Avatar, Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const AnalysesWrapper = styled(Box)(() => ({
  display: "flex",
  width: "100%",
  overflow: "hidden",
  gap: "12px",
}));

export const GraphWrapper = styled(Box)(() => ({
  "& > :first-of-type": {
    height: "100%",
  },

  height: "100%",
  "& img": {
    maxWidth: "40%",
  },
}));

export const TableWrapper = styled(Box)(() => ({
  "& > :first-of-type": {
    height: "100%",
  },
}));

export const TableContainer = styled(Box)(() => ({
  height: "441px",
  overflowY: "auto",
  borderRadius: "12px",
  border: "1px solid #EAECF0",
}));

export const AccordionContent = styled(AccordionSummary)(() => ({
  height: "50px",
  padding: "0px 24px",

  "& p": {
    fontSize: "16px",
    fontFamily: "Inter",
    color: "#5E5873",
  },
}));

export const FullScreenBtnWrapper = styled(Box)(() => ({
  alignSelf: "flex-end",
  marginTop: "11px",
}));

export const UserAvatar = styled(Avatar)(() => ({
  width: "47.5px",
  height: "47.5px",
  backgroundColor: "#383838",

  ".MuiAvatar-img": {
    objectFit: "contain",
  },
}));

export const ChatAvatar = styled(Avatar)(() => ({
  width: "52px",
  height: "55px",
  backgroundColor: "tranparent",
  transform: "rotate(90deg)",

  ".MuiAvatar-img": {
    //objectFit: "contain",
  },
}));

export const AccordionWrapper = styled(Box)(() => ({
  height: "441px",
  overflowY: "auto",
  overflowX: "hidden",
  p: "0 1px",
}));
