import { Fragment, useState } from 'react';

import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid
} from '@mui/material';

import { VaribleList } from './variableList';
import { ShowExspresion } from './showExspresion';
import { KeyBoard } from './keyBoard';
import { FunctionsList }  from './functionsList';

export function CreateNewVariableDialogContent({
  hadleCreate,
  expression,
  setExpression,
  newVariableName,
  setNewVariableName,
  handleClose
}) {
  

  const addItemToExpression = (value) => {
    let expressionCopy = [...expression];
    const lastIndex = expressionCopy.length - 1;
    if((
      value.type === 'variable' ||
      value.type === 'function' ||
      value.type === 'number'
      ) && (
      expressionCopy[lastIndex]?.type === 'variable' ||
      expressionCopy[lastIndex]?.type === 'number'
    )) {
      return
    }
    if(value.type === 'number' && expressionCopy[lastIndex]?.type === 'number') {
      expressionCopy[lastIndex].value += value.value;
    } else if (
      value.type === 'variable' &&
      expressionCopy[lastIndex]?.type === 'function' &&
      expressionCopy[lastIndex].parametersLength > expressionCopy[lastIndex].parameters.length
    ) {
      expressionCopy[lastIndex].parameters.push(value.value)
    } else {
      expressionCopy.push(value);
    }
    setExpression(expressionCopy);
  };

  const deleteItemfromExpression = () => {
    let expressionCopy = [...expression];
    const lastIndex = expressionCopy.length - 1;
    if(expressionCopy[lastIndex]?.type === 'number' && expressionCopy[lastIndex].value.length) {
      expressionCopy[lastIndex].value = expressionCopy[lastIndex].value.slice(0, expressionCopy[lastIndex].value.length - 1)
      if(!expressionCopy[lastIndex].value.length) {
        expressionCopy.pop();
      }
    } else {
      expressionCopy.pop();
    }
    setExpression(expressionCopy);
  }

  return (
    <Fragment>
      <DialogTitle>
        Create a new variable
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item>
            <VaribleList
              addItemToExpression={addItemToExpression}
              newVariableName={newVariableName}
              setNewVariableName={setNewVariableName}
            />
          </Grid>
          <Grid item flex={1}>
            <ShowExspresion expression={expression}/>
            <Grid item container spacing={2}>
              <Grid item flex={1}>
                <FunctionsList addItemToExpression={addItemToExpression}/>
              </Grid>
              <Grid item>
                <KeyBoard
                  addItemToExpression={addItemToExpression}
                  deleteItemfromExpression={deleteItemfromExpression}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color='info' onClick={handleClose}>Cancel</Button>
        <Button variant="contained" color="secondary" onClick={hadleCreate}>Create</Button>
      </DialogActions>
    </Fragment>
  )
}