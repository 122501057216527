import { ActiveChat } from "./components/ActiveChat";
import { UserNavbar } from "./components/UserNavbar";

import { ChatContentWrapper } from "./styled";

export const ChatContent = () => {
  return (
    <ChatContentWrapper>
      <UserNavbar />
      <ActiveChat />
    </ChatContentWrapper>
  );
};
