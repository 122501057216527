import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";

export const useActiveChat = ({ value, sendMessage, messagesList }) => {
  const [arrowStep, setArrowStep] = useState(0);
  const [message, setMessage] = useState(value || "");
  const [userMessages, setUserMessages] = useState([]);

  const onChange = (event) => {
    const { value } = event.target;
    setMessage(value);
  };

  const filterMessagesByUser = useCallback((messages) => {
    if (!messages.length) return [];
    const filtredList = messages.filter(({ identity }) => identity === "user");
    return setUserMessages(!!filtredList.length ? filtredList.reverse() : []);
  }, []);

  useEffect(() => {
    filterMessagesByUser(messagesList);
    setArrowStep(0);
    setMessage("");
  }, [filterMessagesByUser, messagesList]);

  useEffect(() => {
    if (arrowStep) {
      setMessage(userMessages[arrowStep - 1]?.text);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arrowStep]);

  const handleSubmit = useCallback(() => {
    if (message.length > 1) {
      sendMessage(message);
      return setMessage("");
    }
    return toast.error("Message should have more than 1 letter");
  }, [message, sendMessage]);

  const handleSetExampleMessage = (example) => {
    setMessage(example);
  };

  const handleIncreaseArrowStep = useCallback(() => {
    if (arrowStep + 1 > userMessages.length) return;
    setArrowStep((prev) => prev + 1);
  }, [arrowStep, userMessages.length]);

  const handleDecreaseArrowStep = useCallback(() => {
    if (arrowStep - 1 < 0) return;
    if (arrowStep - 1 >= userMessages.length || arrowStep - 1 === 0) {
      setArrowStep(0);
      setMessage("");
      return;
    }
    setArrowStep((prev) => prev - 1);
  }, [arrowStep, userMessages.length]);

  const handleKeyDown = (event) => {
    switch (event.code) {
      case "ArrowUp":
        return handleIncreaseArrowStep();
      case "ArrowDown":
        return handleDecreaseArrowStep();
      case "Enter":
        return handleSubmit();
      default:
        break;
    }
  };

  return {
    onChange,
    handleKeyDown,
    handleSetExampleMessage,
    filterMessagesByUser,
    message,
    handleSubmit,
  };
};
