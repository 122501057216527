export const HeaderLinks = [
  {
    title: "Research",
    route: "/genie-gpt",
  },
  {
    title: "Product",
    route: "/genie-gpt",
  },
  {
    title: "Developers",
    route: "/genie-gpt",
  },
  {
    title: "Safety",
    route: "/genie-gpt",
  },
  {
    title: "Company",
    route: "/genie-gpt",
  },
  {
    title: "About Statify",
    route: "/",
  },
];
