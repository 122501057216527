import { Grid } from "@mui/material";
import { styled } from "@mui/system";

export const UserNavbarWrapper = styled(Grid)(() => ({
  display: "flex",
  gap: "24px",
  alignItems: "center",
  width: "100%",
  justifyContent: "flex-end",
  padding: "0px 50px 0px 50px",
  height: "48px",
  marginBottom: "28px",
}));
