import {
  SET_DATASETS,
  CHANGE_FILTERS,
  OPEN_CREATE_DIALOG,
  OPEN_SETTINGS_DIALOG,
  SET_DATASET_FILE,
  HANDLE_CHANGE_CONFIGURATION,
  HANDLE_CHANGE_VAR_TYPES,
  SET_DATA,
  SET_SELECTED_VARIBLES,
  SET_ERRORS,
  CLEAR_FILTERS,
  SET_DATASET_NAMES,
} from "../actions/datasets";

const initialState = {
  list: [],
  namesList: [],
  filters: {
    project_id: null,
    limit: 25,
    offset: 0,
    page: 0,
  },
  dataset: {},
  dataSetFile: null,
  datasetCnfiguration: null,
  datasetVarTypes: null,
  data: [],
  frequency: [],
  summary: [],
  selectedVaribles: [],
  datasetAnalyses: [],
  count: 0,
  openedCreateDialog: false,
  openedSettingsDialog: false,
  errors: [],
};

const datasetsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DATASETS:
      return { ...state, list: action.list, count: action.count };

    case CHANGE_FILTERS:
      return { ...state, filters: action.filters };

    case CLEAR_FILTERS:
      return { ...state, filters: initialState.filters };

    case OPEN_CREATE_DIALOG:
      return { ...state, openedCreateDialog: action.open };

    case OPEN_SETTINGS_DIALOG:
      return { ...state, openedSettingsDialog: action.open };

    case SET_DATASET_FILE:
      return {
        ...state,
        datasetCnfiguration: action.dataSetFile.configuration,
        datasetVarTypes: action.dataSetFile.variable_types,
        dataSetFile: action.dataSetFile,
      };

    case HANDLE_CHANGE_CONFIGURATION:
      return { ...state, datasetCnfiguration: action.configuration };

    case HANDLE_CHANGE_VAR_TYPES:
      return { ...state, datasetVarTypes: action.varTypes };

    case SET_DATASET_NAMES:
      return { ...state, namesList: action.namesList };
    case SET_DATA:
      return {
        ...state,
        data: action.data || state.data,
        frequency: action.frequency || state.frequency,
        summary: action.summary || state.summary,
        datasetAnalyses: action.datasetAnalyses || state.datasetAnalyses,
        count: action.count || state.count,
      };

    case SET_SELECTED_VARIBLES:
      return { ...state, selectedVaribles: action.varibles };

    case SET_ERRORS:
      return { ...state, errors: action.errors };

    default:
      return state;
  }
};

export default datasetsReducer;
