import { useState } from "react";

import { AnalyseTable } from "../AnalyseTable";
import { AnalyseGraphsContent } from "../AnalyseGraphsContent";
import { AnalyseContentWrapper } from "../AnalyseContentWrapper";
import { CorelationAcardion } from "../Accordions/CorelationAcardion";

import { GraphWrapper, TableWrapper } from "../AnalyseItem/styled";

import { Tab, Tabs } from "@mui/material";

export const AnalyseTabs = ({ tabs, currentAnalyse, callback }) => {
  const [value, setValue] = useState(!!tabs.tabs.length ? tabs.tabs[0] : "");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleGetComponent = (data) => {
    switch (currentAnalyse.analysis_type) {
      case "compare_groups":
        return <AnalyseTable data={data} />;
      case "dependent":
        return <AnalyseTable data={data} />;
      case "correlations":
        return <CorelationAcardion data={data} />;
      default:
        break;
    }
  };

  return (
    <>
      {tabs?.tabs?.length > 1 && (
        <Tabs
          scrollButtons={false}
          variant="scrollable"
          value={value}
          onChange={handleChange}
          sx={{
            "& button": {
              fontSize: "16px",
              fontFamily: "Axiforma",
              color: "#1E1D23 !important",
            },
          }}
        >
          {tabs.tabs?.map((item, index) => (
            <Tab value={item} label={item} key={index} />
          ))}
        </Tabs>
      )}

      {tabs.content && (
        <AnalyseContentWrapper
          table={handleGetComponent(value ? tabs.content[value] : tabs.content)}
          callback={callback}
          currentAnalyse={currentAnalyse}
          currentTab={value}
          callbackGraphItem={
            <GraphWrapper>
              <AnalyseGraphsContent
                data={currentAnalyse?.summary?.images}
                analysisType={currentAnalyse.analysis_type}
                independent={false}
                currentTab={value}
              />
            </GraphWrapper>
          }
          callbackItem={
            <TableWrapper>{handleGetComponent(value ? tabs.content[value] : tabs.content)}</TableWrapper>
          }
        />
      )}
    </>
  );
};
