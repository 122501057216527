import { useState } from "react";

import { AnalyseGraphsContent } from "../AnalyseGraphsContent";
import { MultivariateAccordion } from "../Accordions/MultivariateAccordion";

import { GraphWrapper, TableWrapper } from "../AnalyseItem/styled";

import { Tab, Tabs } from "@mui/material";
import { AnalyseContentWrapper } from "../AnalyseContentWrapper";

export const MultivariateAnalyse = ({
  tabs,
  content,
  callback,
  currentAnalyse,
}) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Tabs
        scrollButtons={false}
        variant="scrollable"
        value={value}
        onChange={handleChange}
        sx={{
          "& button": {
            fontSize: "16px",
            fontFamily: "Axiforma",
            color: "#1E1D23 !important",
          },
        }}
      >
        {tabs?.map((item, index) => (
          <Tab label={item} key={index} />
        ))}
      </Tabs>

      <AnalyseContentWrapper
        table={<MultivariateAccordion data={content.multivariateTabs[value]} />}
        callback={callback}
        currentAnalyse={currentAnalyse}
        independent={value === 0 ? true : false}
        callbackGraphItem={
          <GraphWrapper>
            <AnalyseGraphsContent
              independent={value === 0 ? true : false}
              data={currentAnalyse?.summary?.images}
              analysisType={currentAnalyse.analysis_type}
            />
          </GraphWrapper>
        }
        callbackItem={
          <TableWrapper>
            <MultivariateAccordion data={content.multivariateTabs[value]} />
          </TableWrapper>
        }
      />
    </>
  );
};
