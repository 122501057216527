import Home from "./pages/home";
import Owerview from "./pages/owerview/index";
import Dataset from "./pages/dataset/index";
import Analyse from "./pages/analyse/index";
import Project from "./pages/project/index";
import Projects from "./pages/owerview/projectsPage";
import Datasets from "./pages/owerview/datasetPage";
import Analyses from "./pages/owerview/analysesPage";
import Collection from "./pages/colection";
import { Layout } from "./components/layout";
import { GPT } from "./pages/gpt";

export const routes = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/genie-gpt",
    element: <GPT />,
  },
  {
    path: "forgot-password",
  },
  {
    path: "reset-password",
  },
  {
    path: "dashboard",
    element: <Layout />,
    children: [
      {
        path: "",
        element: <Owerview />,
      },
      {
        path: "dataset/:id",
        element: <Dataset />,
      },
      {
        path: "analyse/:id",
        element: <Analyse />,
      },
      {
        path: "project/:id",
        element: <Project />,
      },
      {
        path: "projects",
        element: <Projects />,
      },
      {
        path: "datasets",
        element: <Datasets />,
      },
      {
        path: "analysis",
        element: <Analyses />,
      },
      {
        path: "collection",
        element: <Collection />,
      },
    ],
  },
];
