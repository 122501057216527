import { useEffect, useState } from "react";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionActions,
  Typography,
  Box,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const AnalyseGraph = ({ data }) => {
  const [graphs, setGraphs] = useState([]);

  useEffect(() => {
    setGraphs(Object.keys(data));
  }, [data]);

  return (
    <>
      {graphs.map((graph, index) => (
        <Accordion key={index} defaultExpanded={!index}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{
              height: "50px",
              padding: "0px 24px",

              "& p": {
                fontSize: "16px",
                fontFamily: "Inter",
                color: "#5E5873",
              },
            }}
          >
            <Typography>{graph === "null" ? "heatmap" : graph}</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: "0px 24px" }}>
            <Box
              sx={{
                background: "#fff",
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <img style={{ width: "100%" }} src={data[graph]} alt="graph" />
            </Box>
          </AccordionDetails>
          <AccordionActions></AccordionActions>
        </Accordion>
      ))}
    </>
  );
};
