import GPTLogo from "../../../../images/gpt-logo.png";

import Grid from "@mui/material/Grid";

export const WelcomeScreen = () => {
  return (
    <Grid
      container
      alignItems="center"
      pt="50px"
      height="calc(100vh - 94px)"
      sx={{
        "& img": {
          ml: "100px",
          width: "80%",
          minWidth: "800px",
        },
      }}
    >
      <img alt="Logo" src={GPTLogo} />
    </Grid>
  );
};
