import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
  DefaultNavbarItem,
  IconWrapper,
  LongText,
  NavbarItem,
  StylesInput,
} from "./styles";

import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import { Typography } from "@mui/material";

export const NavbarLink = ({
  id,
  type = "default",
  icon,
  title,
  active = false,
  onClick,
  handleDeleteChat,
  handleUpdateChat,
}) => {
  const { id: activeChatId } = useSelector(
    (state) => state.chatsReducer.activeChat
  );
  const [isEditing, setIsEditing] = useState(false);
  const [editedName, setEditedName] = useState(title);

  const handleEditClick = (event) => {
    event.stopPropagation();
    setIsEditing(true);
  };

  const handleSaveClick = (event) => {
    event.stopPropagation();
    if (title !== editedName) handleUpdateChat(id, editedName);
    setIsEditing(false);
  };

  const handleCancelClick = (event) => {
    event.stopPropagation();
    setIsEditing(false);
    setEditedName(title);
  };

  const handleDeleteClick = (event) => {
    event.stopPropagation();
    handleDeleteChat(true);
  };

  useEffect(() => {
    if (activeChatId && activeChatId !== id) {
      setIsEditing(false);
    }
  }, [activeChatId, id]);

  const handleShowCorrectItem = (name) => {
    switch (name) {
      case "default":
        return (
          <DefaultNavbarItem onClick={onClick}>
            {icon}
            <Typography>{title}</Typography>
          </DefaultNavbarItem>
        );
      case "dataset":
        return (
          <NavbarItem active={active} onClick={onClick}>
            {icon}
            <Typography>{title}</Typography>
          </NavbarItem>
        );
      case "chatLink":
        return (
          <NavbarItem active={active} onClick={onClick}>
            {icon}
            {isEditing ? (
              <StylesInput
                focused
                onClick={(event) => event.stopPropagation()}
                value={editedName}
                onChange={({ target }) => setEditedName(target.value)}
                fullWidth
              />
            ) : (
              <LongText active={active}>{title}</LongText>
            )}
            {isEditing ? (
              <IconWrapper>
                <CheckIcon onClick={(event) => handleSaveClick(event)} />
                <CloseIcon onClick={(event) => handleCancelClick(event)} />
              </IconWrapper>
            ) : (
              <IconWrapper>
                <EditIcon onClick={(event) => handleEditClick(event)} />
                <DeleteIcon onClick={(event) => handleDeleteClick(event)} />
              </IconWrapper>
            )}
          </NavbarItem>
        );

      default:
        break;
    }
  };
  return <>{handleShowCorrectItem(type)}</>;
};
