import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { NavbarLink } from "./components/navbarLink";
import { DatasetTable } from "./components/DatasetTable";
import { SimpleMenu } from "../../../../components/simpleMenu";
import { SimplePopup } from "../../../../components/simplePopup";
import { AnalysePopup } from "../chatContent/components/AnalysePopup";
import { CustomMenuItem, DeleteMenuItem } from "../../../../components/userInfoBar/styles";

import { deleteDatasetById, fetchDatasetNames, openCreateDialog } from "../../../../actions/datasets";
import { addChat, editChat, removeChat, addMessage, setActiveChat } from "../../../../actions/chats";

import GPTLogo from "../../../../images/gpt-logo.png";
import { NoticeIcon } from "../../../../Icons/NoticeIcon";
import { MessageIcon } from "../../../../Icons/MessageIcon";
import { AddMessageIcon } from "../../../../Icons/AddMessageIcon";
import { AddDocumentIcon } from "../../../../Icons/AddDocumentIcon";

import { LinksWrapper, NavbarContent, NavbarWrapper } from "./styles";

import List from "@mui/material/List";

const DATASET_STATE = { id: null, name: "" };

export const ChatNavbar = () => {
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const [popUpElement, setPopUpElement] = useState(null);
  const [showFullScreen, setShowFullScreen] = useState(false);
  const [datasetConfirm, setDatasetConfirm] = useState(false);
  const [newChatConfirm, setNewChatConfirm] = useState(false);
  const [confirmDeleting, setConfirmDeleting] = useState(false);
  const [activeDataset, setActiveDataset] = useState(DATASET_STATE);

  const open = Boolean(anchorEl);

  const chats = useSelector((state) => state.chatsReducer);
  const datasets = useSelector((state) => state.datasetsReducer.namesList);

  const handleOpenMenu = (event, datasetId, datasetName) => {
    setAnchorEl(event.currentTarget);
    setActiveDataset({ id: datasetId, name: datasetName });
  };

  const handleShowDataset = () => {
    setShowFullScreen(true);
    setPopUpElement(<DatasetTable id={activeDataset.id} name={activeDataset.name} />);
  };

  const handleDeleteDataset = () => {
    dispatch(deleteDatasetById(activeDataset.id, () => dispatch(fetchDatasetNames())));
  };

  const handleCloseShowDataset = () => {
    setShowFullScreen(false);
    setPopUpElement(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleActiveLink = (linkId) => {
    const { id } = chats.activeChat;

    if (!id) return false;
    return id && id === linkId;
  };

  const handleUpdateChat = (id, name) => {
    dispatch(editChat(id, { name }));
  };

  const handleDeleteChat = () => {
    dispatch(removeChat(chats.activeChat.id));
    setConfirmDeleting(false);
  };

  const handleDatasetClick = () => {
    const { id } = chats.activeChat;
    if (id) return setDatasetConfirm(true);
    return setNewChatConfirm(true);
  };

  const submitDatasetConfirm = () => {
    const { id, name } = chats.activeChat;

    const data = {
      file_configuration: {
        width: 1200,
        height: 1200,
        resolution: 200,
      },
      data: {
        name,
        chat_id: id,
        user_text: `load dataset ${activeDataset.name}`,
        dataset_id: activeDataset.id,
      },
    };

    dispatch(addMessage(data));
    setDatasetConfirm(false);

    setActiveDataset(DATASET_STATE);
  };

  const submitNewChatConfirm = () => {
    setNewChatConfirm(false);
    setActiveDataset(DATASET_STATE);

    const data = {
      file_configuration: {
        width: 1200,
        height: 1200,
        resolution: 200,
      },
      data: {
        name: `load dataset ${activeDataset.name}`,
        chat_id: null,
        user_text: `load dataset ${activeDataset.name}`,
        dataset_id: activeDataset.id,
      },
    };

    dispatch(addChat(`load dataset ${activeDataset.name}`, data));
  };

  const MENU_ITEMS = [
    {
      title: "Show Dataset",
      onClick: handleShowDataset,
      type: "show",
    },
    {
      title: "Load Dataset",
      onClick: handleDatasetClick,
      type: "show",
    },
    {
      title: "Delete Dataset",
      onClick: handleDeleteDataset,
      type: "delete",
    },
  ];

  return (
    <>
      <NavbarWrapper>
        <img alt="Logo" src={GPTLogo} />

        <NavbarContent>
          <List>
            <NavbarLink
              type="default"
              title="New Chat"
              icon={<AddMessageIcon />}
              onClick={() => dispatch(addChat())}
            />

            <LinksWrapper>
              {chats.list?.map(({ name, id }) => (
                <NavbarLink
                  id={id}
                  key={id}
                  title={name}
                  type="chatLink"
                  icon={<MessageIcon />}
                  active={handleActiveLink(id)}
                  handleUpdateChat={handleUpdateChat}
                  handleDeleteChat={setConfirmDeleting}
                  onClick={() => dispatch(setActiveChat(id, true))}
                />
              ))}
            </LinksWrapper>
          </List>

          <List>
            <NavbarLink
              defaultBtn
              type="default"
              title="New Dataset"
              icon={<AddDocumentIcon />}
              onClick={() => dispatch(openCreateDialog(true))}
            />
            <LinksWrapper>
              {datasets?.map(({ name, id }) => (
                <NavbarLink
                  key={id}
                  id={id}
                  title={name}
                  type="dataset"
                  icon={<NoticeIcon />}
                  handleUpdateChat={handleUpdateChat}
                  handleDeleteChat={setConfirmDeleting}
                  onClick={(event) => handleOpenMenu(event, id, name)}
                />
              ))}
            </LinksWrapper>
          </List>
        </NavbarContent>
        <SimpleMenu handleClose={handleClose} open={open} anchorEl={anchorEl}>
          {MENU_ITEMS.map(({ title, onClick, type }, index) =>
            type === "delete" ? (
              <DeleteMenuItem key={index} onClick={onClick}>
                {title}
              </DeleteMenuItem>
            ) : (
              <CustomMenuItem key={index} onClick={onClick}>
                {title}
              </CustomMenuItem>
            )
          )}
        </SimpleMenu>
      </NavbarWrapper>

      <AnalysePopup
        maxHeight="90vh"
        open={showFullScreen}
        content={popUpElement}
        onClose={handleCloseShowDataset}
      />

      {confirmDeleting && (
        <SimplePopup
          submitBtnText="Delete"
          cancelBtnText="Cancel"
          title="Delete chat?"
          open={confirmDeleting}
          onClose={setConfirmDeleting}
          handleSubmit={handleDeleteChat}
          content={`This will delete ${chats.activeChat.name}.`}
        />
      )}

      {datasetConfirm && (
        <SimplePopup
          submitBtnText="Yes"
          cancelBtnText="No"
          title="Dataset Confirm"
          open={datasetConfirm}
          onClose={setDatasetConfirm}
          handleSubmit={submitDatasetConfirm}
          content="Do you want to load the dataset on the current chat?"
        />
      )}

      {newChatConfirm && (
        <SimplePopup
          submitBtnText="Yes"
          cancelBtnText="No"
          title="Dataset Confirm"
          open={newChatConfirm}
          onClose={setNewChatConfirm}
          handleSubmit={submitNewChatConfirm}
          content={`Do you want to open a new chat and load the dataset “${activeDataset.name}”?`}
        />
      )}
    </>
  );
};
