import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { MessageList } from "../MessageList";
import { ChatInput } from "../../../../../../components/inputs/chatInput";

import { getChatName } from "../../../../../../helpers/getChatName";

import { addChat, addMessage, addFirstMessage } from "../../../../../../actions/chats";

import { ActiveChatWrapper } from "./styles";

export const ActiveChat = () => {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.userReducer);
  const { id, messages, dataset_id, analysis_id, name } = useSelector(
    (state) => state.chatsReducer.activeChat
  );

  const [firstLoading, setFirstLoading] = useState(true);

  const sendMessage = useCallback(
    (text) => {
      const data = {
        file_configuration: {
          width: 1200,
          height: 1200,
          resolution: 200,
        },
        data: {
          name,
          chat_id: id,
          user_text: text,
          dataset_id,
          analysis_id,
          round_digits: 3,
          significance_level: 0.05,
          significance_level_normality: 0.05,
        },
      };
      if (!id) {
        const chatName = getChatName(text, data);
        dispatch(addChat(chatName, data));
      } else {
        if (!messages.length) {
          dispatch(addFirstMessage(data));
        } else {
          dispatch(addMessage(data));
        }
      }
      setFirstLoading(false);
    },
    [analysis_id, dataset_id, dispatch, id, messages.length, name]
  );

  useEffect(() => {
    if (!firstLoading) {
      setFirstLoading(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <ActiveChatWrapper>
      {!!messages.length && (
        <MessageList id={id} userData={data} messages={messages} firstLoading={firstLoading} />
      )}
      <ChatInput label="Type your promt..." sendMessage={sendMessage} name="chat" messages={messages} />
    </ActiveChatWrapper>
  );
};
