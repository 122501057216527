import InputBase from '@mui/material/InputBase';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { Select, MenuItem, Chip } from '@mui/material';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const Field = styled(InputBase)(({ theme, error }) => ({
  'label + &': {
    marginTop: theme.spacing(4)
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
    border: error ? '1px solid #d32f2f' : '1px solid #ced4da',
    fontSize: 16,
    width: '100%',
    padding: '10px 12px',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    '&:focus': {
      borderColor: theme.palette.primary.main,
    },
  },
}));

export function SelectInput({label, value, onChange, items}) {
  
  return (
    <FormControl variant="standard" fullWidth>
      <InputLabel 
        shrink
        sx={{
          color: '#163B65',
          fontSize: '20px'
        }}
      >
        {label}
      </InputLabel>
      <Select
        value={value}
        input={<Field />}
        onChange={onChange}
      >
        {items.map(item => (
          <MenuItem key={item.value} value={item.value}>{item.name}</MenuItem>
        ))}
      </Select>
      <div style={{height: '17px'}}></div>
    </FormControl>
  )
}