import { useEffect, useState } from "react";

import { CopyIcon } from "../../../../../../Icons/CopyIcon";
import { roundNumbers } from "../../../../../../helpers/roundNumbers";

import {
  Box,
  Table,
  Paper,
  Tooltip,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  IconButton,
  TableContainer,
} from "@mui/material";

export const AnalyseTable = ({ data }) => {
  const [headers, setHeaders] = useState([]);

  useEffect(() => {
    if (!!data.length) {
      let keys = Object.keys(data[0]);
      setHeaders(keys);
    }
  }, [data]);

  const copyTextToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };

  return (
    <>
      {!!headers.length && (
        <TableContainer
          component={Paper}
          sx={{
            boxShadow: "none",
            borderRadius: "12px",
            border: "1px solid #EAECF0",
            maxHeight: "441px",
          }}
        >
          <Table stickyHeader sx={{ width: "100%" }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {headers.map((header) => (
                  <TableCell
                    key={`header:${header}`}
                    sx={{
                      fontFamily: "Inter",
                      fontWeight: 400,
                      minWidth: "150px",
                      background: "linear-gradient(0deg, #FAFBFB, #FAFBFB)",
                    }}
                  >
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((row, index) => (
                <TableRow hover key={index}>
                  {headers.map((header) =>
                    header !== "Conclusion" ? (
                      <TableCell
                        sx={{
                          fontWeight: "500 !important",
                          fontFamily: "Inter",
                        }}
                        key={header + index}
                        align="left"
                      >
                        {roundNumbers(row[header])}
                      </TableCell>
                    ) : (
                      <Tooltip
                        followCursor
                        enterDelay={500}
                        title={row.Conclusion}
                        key={header + index}
                        sx={{ cursor: "pointer" }}
                        onClick={() => {
                          navigator.clipboard.writeText(row.Conclusion);
                        }}
                      >
                        <TableCell
                          align="left"
                          sx={{
                            fontFamily: "Inter",
                            fontWeight: "500 !important",
                            "& svg": {
                              opacity: 0,
                              transition: "0.3s",
                            },
                            ":hover": {
                              "& svg": {
                                opacity: 1,
                                transition: "0.3s",
                              },
                            },
                          }}
                        >
                          <Box display="flex" alignItems="center" justifyContent="space-between">
                            <Box maxWidth="80%">{row[header]}</Box>

                            <IconButton
                              onClick={() => {
                                copyTextToClipboard(row[header]);
                              }}
                            >
                              <CopyIcon />
                            </IconButton>
                          </Box>
                        </TableCell>
                      </Tooltip>
                    )
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};
