import { ListItem, List } from "@mui/material";
import { styled } from "@mui/system";

export const ListContainer = styled(List)(() => ({
  display: "flex",
  padding: 0,
  gap: "24px",
}));

export const StyledListItem = styled(ListItem)(() => ({
  width: "100%",
  padding: 0,
  textWrap: "nowrap",
  color: "#ffff",
  cursor: "pointer",
  fontWeight: 400,
  fontSize: "15px",
  transition: "0.2s",

  "&:hover": {
    color: "#1CB4AE",
  },
}));
