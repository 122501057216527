import { Box, ListItem, TextField, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const NavbarItem = styled(ListItem, {
  shouldForwardProp: (prop) => prop !== "active",
})(({ active }) => ({
  color: active ? "#66DDCB" : "#BDBDBD",
  fontFamily: "Axiforma",
  fontWeight: 400,
  fontSize: "16px",
  padding: "14px 12px",
  gap: "12px",
  lineHeight: "20px",
  textAlign: "center",
  cursor: "pointer",
  transition: "0.3s",
  overflow: "hidden",
  height: "53px",

  "& div": {
    display: active ? "block" : "none",
    transition: "0.3s",
  },

  "& svg path": {
    stroke: active ? "#66DDCB" : "#BDBDBD",
    transition: "0.3s",
  },

  "& p": {
    width: active ? "118px" : "162px",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    fontFamily: "Axiforma",
    marginTop: "4px",
    display: "flex",
    alignItems: "center",
  },

  ":hover": {
    "& p": {
      color: "#66DDCB",
    },

    "& svg path": {
      stroke: "#66DDCB",
      transition: "0.3s",
    },
  },
}));

export const DefaultNavbarItem = styled(ListItem)(() => ({
  color: "#F2F2F2",
  textTransform: "capitalize",
  padding: "12px 12px",
  display: "flex",
  gap: "12px",
  height: "53px",
  alignItems: "center",
  borderRadius: "12px",
  cursor: "pointer",
  border: "1px solid rgba(255, 255, 255, 0.40)",
  transition: "0.3s",

  "& svg path": {
    transition: "0.3s",
  },

  "& p": {
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    fontFamily: "Axiforma",
    marginTop: "3px",
    display: "flex",
    alignItems: "center",
  },

  ":hover": {
    border: "1px solid #66DDCB",
    color: "#66DDCB",

    "& svg path": {
      fill: "#66DDCB",
    },
  },
}));

export const StylesInput = styled(TextField)(() => ({
  width: "118px",

  "& .MuiOutlinedInput-input": {
    padding: 0,
    height: "28px",
    fontFamily: "Axiforma",
    fontWeight: 400,
    fontSize: "16px",
    color: "#66DDCB",
    marginTop: "4px",
    border: "none",
  },

  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      border: "none",
    },
    "&.Mui-focused fieldset": {
      border: "none",
    },
  },
}));

export const IconWrapper = styled(Box)(() => ({
  position: "absolute",
  right: "10px",
  height: "24px",

  "& svg": {
    width: "20px",
  },

  "& :first-of-type": {
    marginRight: "3px",
  },

  "& svg path": {
    fill: "#BDBDBD",
    stroke: "#051823 !important",
  },
}));

export const LongText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "active",
})(({ active }) => ({
  position: "relative",
  width: active ? "118px" : "162px",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",

  "&::after": {
    content: '""',
    position: "absolute",
    top: 0,
    right: "0px",
    width: "20px",
    height: "28px",
    background: `linear-gradient(to right, transparent, #041823)`,
    pointerEvents: "none",
  },
}));
