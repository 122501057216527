import { Button } from "@mui/material";
import { styled } from "@mui/system";

export const IconWrapper = styled(Button)(() => ({
  borderRadius: "41px",
  padding: "12px 24px",
  flexWrap: "nowrap",
  alignItems: "center",
  textTransform: "uppercase",
  gap: "10px",
  backgroundImage: "linear-gradient(140deg, #0CECC7 0%, #18B8DC 100%)",

  "& p": {
    fontFamily: "Axiforma",
    fontSize: "12px",
    fontWeight: "500",
    color: "#041823",
    textTransform: "capitalize",
  },
}));
