import { SvgIcon } from "@mui/material";
import React from "react";

export const AddDocumentIcon = () => {
  return (
    <SvgIcon
      style={{ width: "20px", height: "20px" }}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 2H8C4.5 2 3 4 3 7V11.47C3 11.82 3.34 12.06 3.66 11.94C4.76 11.51 6.01 11.37 7.3 11.63C9.9 12.15 11.97 14.29 12.4 16.9C12.66 18.49 12.34 20 11.61 21.25C11.41 21.58 11.65 22 12.04 22H16C19.5 22 21 20 21 17V7C21 4 19.5 2 16 2ZM18.5 9.25H16.5C14.98 9.25 13.75 8.02 13.75 6.5V4.5C13.75 4.09 14.09 3.75 14.5 3.75C14.91 3.75 15.25 4.09 15.25 4.5V6.5C15.25 7.19 15.81 7.75 16.5 7.75H18.5C18.91 7.75 19.25 8.09 19.25 8.5C19.25 8.91 18.91 9.25 18.5 9.25Z"
        fill="#F2F2F2"
      />
      <path
        d="M6 13C3.245 13 1 15.245 1 18C1 20.755 3.245 23 6 23C8.755 23 11 20.755 11 18C11 15.245 8.755 13 6 13ZM8 18.375H6.375V20C6.375 20.205 6.205 20.375 6 20.375C5.795 20.375 5.625 20.205 5.625 20V18.375H4C3.795 18.375 3.625 18.205 3.625 18C3.625 17.795 3.795 17.625 4 17.625H5.625V16C5.625 15.795 5.795 15.625 6 15.625C6.205 15.625 6.375 15.795 6.375 16V17.625H8C8.205 17.625 8.375 17.795 8.375 18C8.375 18.205 8.205 18.375 8 18.375Z"
        fill="#F2F2F2"
      />
    </SvgIcon>
  );
};
