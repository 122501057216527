import React from "react";

export const AddMessageIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: "20px", height: "20px" }}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M5.21 13.51C4.12 13.44 3 13.84 2.17 14.67C0.61 16.23 0.61 18.76 2.17 20.33C2.7 20.86 3.35 21.21 4.04 21.38C4.42 21.48 4.82 21.51 5.22 21.49C6.17 21.45 7.1 21.07 7.83 20.34C8.86 19.31 9.21 17.86 8.88 16.55C8.72 15.86 8.36 15.21 7.83 14.68C7.11 13.95 6.17 13.56 5.21 13.51ZM7.24 17.48C7.24 17.69 7.16 17.87 7.02 18.01C6.88 18.15 6.7 18.23 6.49 18.23H5.75V19.01C5.75 19.22 5.67 19.4 5.53 19.54C5.39 19.68 5.21 19.76 5 19.76C4.59 19.76 4.25 19.42 4.25 19.01V18.23H3.5C3.09 18.23 2.75 17.89 2.75 17.48C2.75 17.07 3.09 16.73 3.5 16.73H4.25V16.02C4.25 15.61 4.59 15.27 5 15.27C5.41 15.27 5.75 15.61 5.75 16.02V16.73H6.49C6.91 16.73 7.24 17.07 7.24 17.48Z"
        fill="#F2F2F2"
      />
      <path
        d="M17.25 2.43005H7.75C4.9 2.43005 3 4.33005 3 7.18005V11.6401C3 11.9901 3.36 12.2401 3.7 12.1501C4.12 12.0501 4.55 12.0001 5 12.0001C7.86 12.0001 10.22 14.3201 10.48 17.1301C10.5 17.4101 10.73 17.6301 11 17.6301H11.55L15.78 20.4501C16.4 20.8701 17.25 20.4101 17.25 19.6501V17.6301C18.67 17.6301 19.86 17.1501 20.69 16.3301C21.52 15.4901 22 14.3001 22 12.8801V7.18005C22 4.33005 20.1 2.43005 17.25 2.43005ZM15.83 10.8101H9.17C8.78 10.8101 8.46 10.4901 8.46 10.1001C8.46 9.70005 8.78 9.38005 9.17 9.38005H15.83C16.22 9.38005 16.54 9.70005 16.54 10.1001C16.54 10.4901 16.22 10.8101 15.83 10.8101Z"
        fill="#F2F2F2"
      />
    </svg>
  );
};
