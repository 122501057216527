import React, { useEffect } from "react";
import { useRoutes, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { routes } from "./routes";
import { Backdrop, Box, CircularProgress } from "@mui/material";
import { ErrorPopup } from "./components/errorPopup";
import axios from "axios";

import { checkSession, setErrors, setShowErrors, setLoading } from "./actions/user";

import "./App.css";
import "react-toastify/dist/ReactToastify.css";

const API_URL = process.env.REACT_APP_API_URL;

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector((state) => state.userReducer.loading);
  useEffect(() => {
    axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (error.code === "ERR_NETWORK") {
          dispatch(setLoading(false));
          dispatch(setErrors([{ msg: "Something went wrong" }]));
          dispatch(setShowErrors(true));
          return;
        }
        console.log(error);
        if (401 === error.response.status) {
          navigate("/");
        } else {
          dispatch(setLoading(false));
          if (error.config.url !== `${API_URL}/analyses/v2/statGPT/`) {
            dispatch(setErrors(error.response.data.detail));
            dispatch(setShowErrors(true));
          }
          return Promise.reject(error);
        }
      }
    );
    dispatch(checkSession(() => navigate("/genie-gpt")));
  }, [dispatch, navigate]);

  const content = useRoutes(routes);

  return (
    <div className="App">
      {content}
      <ToastContainer position="top-right" autoClose={5000} theme={"colored"} />
      <ErrorPopup />
      <Backdrop sx={{ color: "#fff", zIndex: 10001 }} open={loading}>
        <Box display={"flex"} flexDirection="column" alignItems={"center"}>
          <CircularProgress color="inherit" />
        </Box>
      </Backdrop>
    </div>
  );
}

export default App;
