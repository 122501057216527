import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { PageTitle } from '../../components/pageTitle';
import { OwerviewStats } from './OwerviewStats';
import { ProjectsTable } from './projectsTable';

import { fetchProjects } from '../../actions/projects';
import { Box } from '@mui/material';


export default function Projects() {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchProjects());
  }, [])

  return (
    <Box>
      <PageTitle title='Projects' />
      <OwerviewStats only={'projects'}/>
      <ProjectsTable />
    </Box>
    
  )
}