import { styled } from "@mui/system";

import IconButton from "@mui/material/IconButton";
import DialogTitle from "@mui/material/DialogTitle";

export const UserDialogTitle = styled(DialogTitle)(() => ({
  borderBottom: "1px solid #474551",
  fontSize: "20px",
  padding: "24px",
}));

export const CloseIconButton = styled(IconButton)(() => ({
  position: "absolute",
  right: 8,
  top: 8,
  color: "#474551",
}));
