import { Avatar, Box, MenuItem, Typography } from "@mui/material";
import { styled } from "@mui/system";

export const UserName = styled(Typography)(() => ({
  fontFamily: "Axiforma",
  fontSize: "12px",
  fontWeight: "500",
  color: "#292D32",
  textTransform: "capitalize",
  lineHeight: "14px",
}));

export const UserRole = styled(Typography)(() => ({
  fontFamily: "Axiforma",
  fontSize: "10px",
  fontWeight: "400",
  color: "#292D32",
  textTransform: "capitalize",
  opacity: ".7",
  lineHeight: "14px",
}));

export const UserAvatar = styled(Avatar)(() => ({
  border: "2px solid #0DE9C5",
  width: "36px",
  height: "36px",
  fontSize: "15px",
  backgroundColor: "#383838",
}));

export const ContentContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  marginTop: "2px",
}));

export const IconWrapper = styled(Box)(() => ({
  marginLeft: "5px",
  // marginBottom: "6px",
  marginTop: "8px",
  cursor: "pointer",
}));

export const CustomMenuItem = styled(MenuItem)(() => ({
  fontSize: "14px",
  fontFamily: "Axiforma",
  fontWeight: "400",
  color: "#292D32",
  textTransform: "capitalize",
  minWidth: "150px",

  "& svg": {
    margin: "0px 10px 3px 0px",
  },
}));

export const DeleteMenuItem = styled(MenuItem)(() => ({
  fontSize: "14px",
  fontFamily: "Axiforma",
  fontWeight: "400",
  borderTop: "1px solid #dcdae1",
  color: "#e74c3c",
  textTransform: "capitalize",
  minWidth: "150px",
}));
