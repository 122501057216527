import { useState } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";

import { registerUser, clearErrors } from "../../actions/user";

import {
  GOOGLE_ID,
  DEFAULT_USER_DATA,
  getRegistrationFields,
} from "./register.constants";

import {
  ErrorText,
  GreenSpan,
  Line,
  LineWrapper,
  StyledDialog,
  StyledDialogContent,
  TextInput,
} from "./styles";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

export default function RegisterPopUp({
  open,
  handleClose,
  setOpenLogin,
  googleResponse,
  googleErrorResponse,
}) {
  const dispatch = useDispatch();
  const [userData, setUserData] = useState(DEFAULT_USER_DATA);

  const errors = useSelector((state) => state.userReducer.errors);

  const handleRegister = () => {
    dispatch(registerUser(userData, () => onClose()));
  };

  const handleUserInfo = (event) => {
    const { value, name } = event.target;
    setUserData({ ...userData, [name]: value });
  };

  const handleOpenLogIn = (event) => {
    event.stopPropagation();
    handleClose();
    setOpenLogin(true);
  };

  const onClose = () => {
    setUserData(DEFAULT_USER_DATA);
    dispatch(clearErrors());
    handleClose();
  };

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required("Required field"),
    last_name: Yup.string().required("Required field"),
    email: Yup.string().email("Email is not valid").required("Required field"),
    password: Yup.string()
      .required("Required field")
      .min(3, "Password must be at least 8 characters")
      .max(255, "Password cannot be longer than 255 characters"),
    confirm_password: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Required field"),
  });

  const checkError = (field) => {
    let error = "";
    if (Array.isArray(errors)) {
      errors.forEach((err) => {
        if (err.loc[1] === field) {
          error = err.msg;
        }
      });
    }

    return error;
  };

  const fields = getRegistrationFields(userData);

  return (
    <StyledDialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <Formik
        initialValues={DEFAULT_USER_DATA}
        validationSchema={validationSchema}
        onSubmit={handleRegister}
      >
        {({ setFieldValue, setFieldTouched }) => (
          <Form>
            <StyledDialogContent>
              <Typography variant="h4">Register</Typography>
              <Grid container gap="28px" width="100%">
                {fields.map(({ name, label, value, type }, index) => (
                  <Field name={name} key={index}>
                    {({ meta }) => (
                      <Box sx={{ width: "100%" }}>
                        <TextInput
                          fullWidth
                          type={type}
                          name={name}
                          label={label}
                          value={value}
                          onBlur={() => setFieldTouched(name, true)}
                          error={!!meta.error && !!meta.touched}
                          onChange={(event) => {
                            handleUserInfo(event);
                            setFieldValue(name, event.target.value);
                          }}
                        />
                        <ErrorText>
                          {!!meta.error && !!meta.touched
                            ? meta.error
                            : checkError("name")}
                        </ErrorText>
                      </Box>
                    )}
                  </Field>
                ))}
              </Grid>

              <Grid
                container
                gap="18px"
                alignItems="center"
                flexDirection="column"
              >
                <Button type="submit" variant="contained" color="secondary">
                  Create
                </Button>
                <Typography fontSize="14px">
                  Already have an account?
                  <GreenSpan onClick={handleOpenLogIn}>Log in</GreenSpan>
                </Typography>
                <LineWrapper>
                  <Line />
                  <Typography>OR</Typography>
                  <Line />
                </LineWrapper>
                <Grid sx={{ textAlign: "center" }}>
                  <GoogleOAuthProvider clientId={GOOGLE_ID}>
                    <GoogleLogin
                      size="large"
                      onSuccess={googleResponse}
                      onError={googleErrorResponse}
                      width="392"
                    />
                  </GoogleOAuthProvider>

                  <Typography fontSize="14px" mt="28px">
                    After clicking the "Create" button, you agree to the
                    <GreenSpan>Terms of conditions</GreenSpan> and
                    <GreenSpan>Privacy Policy</GreenSpan>
                  </Typography>
                </Grid>
              </Grid>
            </StyledDialogContent>
          </Form>
        )}
      </Formik>
    </StyledDialog>
  );
}
