import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const getChats = (filters) => {
  const sessionToken = localStorage.getItem("session_token");
  const keys = Object.keys(filters);
  let filter_params = "";
  keys.forEach((key) => {
    if (filters[key] || filters[key] === 0) {
      filter_params += `${key}=${filters[key]}&`;
    }
  });

  return axios.get(`${API_URL}/chats/?${filter_params}`, {
    headers: {
      Authorization: `Bearer ${sessionToken}`,
    },
  });
};

export const createChat = (data) => {
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${localStorage.getItem("session_token")}`;

  return axios.post(`${API_URL}/chats/create/`, data);
};

export const deleteChat = (id) => {
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${localStorage.getItem("session_token")}`;

  return axios.delete(`${API_URL}/chats/${id}/`);
};

export const createStatMessage = (data) => {
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${localStorage.getItem("session_token")}`;

  return axios.post(`${API_URL}/analyses/v2/statGPT/`, data);
};

export const createMessage = (data) => {
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${localStorage.getItem("session_token")}`;

  return axios.post(`${API_URL}/chats/message/add/`, data);
};

export const updateChat = (id, data) => {
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${localStorage.getItem("session_token")}`;

  return axios.patch(`${API_URL}/chats/update/${id}/`, data);
};

export const getChatById = (filters, id) => {
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${localStorage.getItem("session_token")}`;

  const keys = Object.keys(filters);
  let filter_params = "";
  keys.forEach((key) => {
    if (filters[key] || filters[key] === 0) {
      filter_params += `${key}=${filters[key]}&`;
    }
  });

  return axios.get(`${API_URL}/chats/${id}/?${filter_params}`);
};
