import { SvgIcon } from "@mui/material";

export const NotificationIcon = ({
  callback = () => {},
  fill = "#ffff",
  backgroundColor = "#fdfdfd",
}) => {
  return (
    <SvgIcon
      onClick={callback}
      xmlns="http://www.w3.org/2000/svg"
      style={{
        cursor: "pointer",
        width: "20px",
        height: "20px",
        fill,
        backgroundColor,
        padding: "8px",
        borderRadius: "50%",
      }}
      viewBox="0 0 20 20"
    >
      <path
        d="M10 5.36664V8.14164"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M10.0166 1.66669C6.94992 1.66669 4.46658 4.15002 4.46658 7.21669V8.96669C4.46658 9.53335 4.23325 10.3834 3.94158 10.8667L2.88325 12.6334C2.23325 13.725 2.68325 14.9417 3.88325 15.3417C7.86658 16.6667 12.1749 16.6667 16.1582 15.3417C17.2832 14.9667 17.7666 13.65 17.1582 12.6334L16.0999 10.8667C15.8082 10.3834 15.5749 9.52502 15.5749 8.96669V7.21669C15.5666 4.16669 13.0666 1.66669 10.0166 1.66669Z"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M12.7751 15.6833C12.7751 17.2083 11.5251 18.4583 10.0001 18.4583C9.24176 18.4583 8.54176 18.1417 8.04176 17.6417C7.54176 17.1417 7.2251 16.4417 7.2251 15.6833"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
    </SvgIcon>
  );
};
