import {
  SET_CHATS,
  SET_ERRORS,
  SET_ACTIVE_CHAT,
  SET_DEFAULT_ACTIVE_CHAT,
  SET_ACTIVE_CHAT_MESSAGES,
} from "../actions/chats";

const initialState = {
  list: [],
  filters: {
    project_id: null,
    limit: 25,
    offset: 0,
    page: 0,
  },
  chatByIdFilters: {
    limit: 25,
    offset: 0,
  },
  data: [],
  count: 0,
  errors: [],
  activeChat: {
    id: null,
    messages: [],
    name: "",
    dataset_id: null,
    analysis_id: null,
  },
};

const chatsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CHATS:
      return { ...state, list: action.list, count: action.count };

    case SET_ERRORS:
      return { ...state, errors: action.errors };

    case SET_ACTIVE_CHAT:
      return { ...state, activeChat: action.activeChat };

    case SET_ACTIVE_CHAT_MESSAGES:
      return {
        ...state,
        activeChat: { ...state.activeChat, messages: action.action },
      };

    case SET_DEFAULT_ACTIVE_CHAT:
      return { ...state, activeChat: initialState.activeChat };

    default:
      return state;
  }
};

export default chatsReducer;
