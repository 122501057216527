import { SvgIcon } from "@mui/material";

export const InfoIcon = ({
  callback = () => {},
  fill = "#ffff",
  backgroundColor = "#fdfdfd",
}) => {
  return (
    <SvgIcon
      onClick={callback}
      xmlns="http://www.w3.org/2000/svg"
      style={{
        cursor: "pointer",
        width: "20px",
        height: "20px",
        fill,
        backgroundColor,
        padding: "8px",
        borderRadius: "50%",
      }}
      viewBox="0 0 20 20"
    >
      <path
        d="M10.0001 18.3334C14.5834 18.3334 18.3334 14.5834 18.3334 10C18.3334 5.41669 14.5834 1.66669 10.0001 1.66669C5.41675 1.66669 1.66675 5.41669 1.66675 10C1.66675 14.5834 5.41675 18.3334 10.0001 18.3334Z"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 6.66669V10.8334"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.99536 13.3333H10.0028"
        stroke="#292D32"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
