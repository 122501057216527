import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

export const getAnalyses = (filters) => {
  const sessionToken = localStorage.getItem("session_token");
  const keys = Object.keys(filters);
  let filter_params = "";
  keys.forEach((key) => {
    if (filters[key]) {
      filter_params += `${key}=${filters[key]}&`;
    }
  });

  return axios.get(`${API_URL}/analyses/?${filter_params}`, {
    headers: {
      Authorization: "Bearer " + sessionToken,
    },
  });
};

export const createCompareGroups = (data) => {
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${localStorage.getItem("session_token")}`;

  return axios.post(`${API_URL}/analyses/v2/create_compare_groups/`, data);
};

export const createCorrelations = (data) => {
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${localStorage.getItem("session_token")}`;

  return axios.post(`${API_URL}/analyses/v2/create_correlation/`, data);
};

export const getAnalyse = (id) => {
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${localStorage.getItem("session_token")}`;
  return axios.get(`${API_URL}/analyses/v2/${id}/`);
};

export const updateCompareGroups = (data) => {
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${localStorage.getItem("session_token")}`;
  return axios.post(`${API_URL}/analyses/v2/update_compare_groups/`, data);
};

export const getExportGraphs = (data) => {
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${localStorage.getItem("session_token")}`;

  return axios({
    method: "post",
    baseURL: API_URL,
    url: "/export/graphs",
    data: data,
  });
};

export const getExportTables = (data) => {
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${localStorage.getItem("session_token")}`;

  return axios({
    method: "post",
    baseURL: API_URL,
    url: "/export/tables",
    data: data,
  });
};

export const updateCorrelation = (data) => {
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${localStorage.getItem("session_token")}`;
  return axios.post(`${API_URL}/analyses/v2/update_correlation/`, data);
};

export const createSurvival = (data) => {
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${localStorage.getItem("session_token")}`;
  return axios.post(`${API_URL}/analyses/create_survival/`, data);
};

export const updateSurvival = (data) => {
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${localStorage.getItem("session_token")}`;
  return axios.post(`${API_URL}/analyses/update_survival/`, data);
};

export const createMultivariate = (data) => {
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${localStorage.getItem("session_token")}`;
  return axios.post(`${API_URL}/analyses/v2/create_multivariate/`, data);
};

export const updateMultivariate = (data) => {
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${localStorage.getItem("session_token")}`;
  return axios.post(`${API_URL}/analyses/v2/update_multivariate/`, data);
};

export const createMixedMultivariate = (data) => {
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${localStorage.getItem("session_token")}`;
  return axios.post(`${API_URL}/analyses/create_mixed_multivariate/`, data);
};

export const updateMixedMultivariate = (data) => {
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${localStorage.getItem("session_token")}`;
  return axios.post(`${API_URL}/analyses/update_mixed_multivariate/`, data);
};

export const deleteAnalyses = (id) => {
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${localStorage.getItem("session_token")}`;
  return axios.delete(`${API_URL}/analyses/${id}/`);
};

export const createScreenTest = (data) => {
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${localStorage.getItem("session_token")}`;
  return axios.post(`${API_URL}/analyses/create_screen_test/`, data);
};
