import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";

export const MessageItem = styled(Box, {
  shouldForwardProp: (prop) => prop !== "identity",
})(({ identity, column }) => ({
  display: "flex",
  maxWidth: "fit-content",
  minWidth: "20%",
  flexDirection: column ? "column" : identity ? "row-reverse" : "row",
  justifyContent: "flex-start",
  alignItems: column ? "flex-start" : "center",
  padding: "24px",
  marginLeft: identity && "auto",
  borderRadius: "12px",
  border: "0.5px solid #C4C4C4",
  background: identity ? "#F4FCFE" : "#fffff",
  gap: "18px",
}));

export const MessageText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "error_text",
})(({ error_text }) => ({
  fontFamily: "Axiforma",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: "500",
  lineHeight: "24px",
  whiteSpace: "pre-line",
  marginTop: "3px",
  color: error_text ? "#FF4343" : "#1E1D23",

  a: {
    color: error_text ? "#FF4343" : "#1E1D23",
  },
}));

export const MessagesWrapper = styled(Box)(() => ({
  overflowY: "auto",
  display: "flex",
  flexDirection: "column",
  gap: "18px",
}));
