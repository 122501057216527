export const getLoginFields = (userData) => [
  {
    type: "text",
    name: "email",
    label: "Email",
    value: userData.email,
  },
  {
    type: "password",
    name: "password",
    label: "Password",
    value: userData.password,
  },
];

export const DEFAULT_USER_DATA = {
  email: "",
  password: "",
};
