export const getRegistrationFields = (userData) => [
  {
    type: "text",
    name: "first_name",
    label: "First Name",
    value: userData.first_name,
  },
  {
    type: "text",
    name: "last_name",
    label: "Last Name",
    value: userData.last_name,
  },
  {
    type: "password",
    name: "password",
    label: "Password",
    value: userData.password,
  },
  {
    type: "password",
    name: "confirm_password",
    label: "Confirm Password",
    value: userData.confirm_password,
  },
  {
    type: "text",
    name: "email",
    label: "Email",
    value: userData.email,
  },
];

export const DEFAULT_USER_DATA = {
  first_name: "",
  last_name: "",
  email: "",
  password: "",
  confirm_password: "",
};

export const GOOGLE_ID = process.env.REACT_APP_GOOGLE_ID;
