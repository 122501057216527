import { useState } from "react";
import { useSelector } from "react-redux";

import { InfoIcon } from "../../../../../../Icons/InfoIcon";
import { UserInfoBar } from "../../../../../../components/userInfoBar";
import { UserSettings } from "../../../../../../components/userSettings";
import { NotificationIcon } from "../../../../../../Icons/NotificationIcon";
import { ReportButton } from "../../../../../../components/buttons/updait-btn";

import { UserNavbarWrapper } from "./styles";

import { Box } from "@mui/system";

export const UserNavbar = () => {
  const { data } = useSelector((state) => state.userReducer);
  const [showSettings, setShowSettings] = useState(false);

  return (
    <>
      <UserNavbarWrapper>
        <ReportButton />
        <Box sx={{ display: "flex", gap: "12px" }}>
          <InfoIcon />
          <NotificationIcon />
        </Box>
        <UserInfoBar
          setShowSettings={setShowSettings}
          first_name={data.first_name}
          last_name={data.last_name}
          role="Admin"
        />
      </UserNavbarWrapper>
      {showSettings && <UserSettings open={showSettings} onClose={setShowSettings} userData={data} />}
    </>
  );
};
