import StyledTooltip from "../../styledTooltip";
import { SendIcon } from "../../../Icons/SendIcon";
import { InfoTooltipContent } from "../../../pages/gpt/components/chatContent/components/InfoTooltipContent";

import { StyledInput } from "./styles";

import { FormControl } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useActiveChat } from "../../../pages/gpt/components/chatContent/components/ActiveChat/useActiveChat";

export const ChatInput = ({ label, value, name, sendMessage, messages }) => {
  const { onChange, handleKeyDown, handleSetExampleMessage, message, handleSubmit } = useActiveChat({
    value,
    sendMessage,
    messagesList: messages,
  });

  return (
    <FormControl fullWidth>
      <StyledInput
        name={name}
        value={message}
        placeholder={label}
        onKeyDown={handleKeyDown}
        onChange={onChange}
        startAdornment={
          <StyledTooltip
            title={<InfoTooltipContent handleSetExampleMessage={handleSetExampleMessage} />}
            placement="top"
            arrow
            PopperProps={{
              modifiers: [{ name: "offset", options: { offset: [410, 0] } }],
            }}
          >
            <InfoOutlinedIcon sx={{ mr: "10px", mt: "0.5px" }} fontSize="small" />
          </StyledTooltip>
        }
        endAdornment={<SendIcon style={{ marginLeft: "8px" }} onClick={handleSubmit} />}
      />
    </FormControl>
  );
};
