import {
  login,
  updateUser,
  createUser,
  getProfile,
  createGoogleUser,
} from "../services/user";

export const SET_USER = "USER/SET_USER";
export const SET_CHECK_USER = "USER/SET_CHECK_USER";
export const SET_ERRORS = "USER/SET_ERRORS";
export const SET_LOADING = "USER/SET_LOADING";
export const SET_SHOW_ERRORS = "USER/SET_SHOW_ERRORS";

export const loginRequest = (data, callback) => {
  return (dispatch) => {
    login(data)
      .then((res) => {
        localStorage.setItem("session_token", res.data.access_token);
        callback();
      })
      .catch((error) => {
        if (error.response)
          dispatch({ type: SET_ERRORS, errors: error.response.data.detail });
      });
  };
};

export const updateRequest = (data, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    updateUser(data)
      .then(({ data }) => {
        dispatch({ type: SET_USER, user: data });
        callback();
      })
      .catch((error) => {
        if (error.response)
          dispatch({ type: SET_ERRORS, errors: error.response.data.detail });
      })
      .finally(() => dispatch(setLoading(false)));
  };
};

export const checkSession = (callback) => {
  return (dispatch) => {
    getProfile().then((res) => {
      dispatch({ type: SET_USER, user: res.data });
      if (window.location.pathname === "/") callback();
    });
  };
};

export const registerUser = (data, callback) => {
  return (dispatch) => {
    createUser(data)
      .then((res) => {
        callback();
      })
      .catch((error) => {
        if (error.response)
          dispatch({ type: SET_ERRORS, errors: error.response.data.detail });
      });
  };
};

export const registerGoogleUser = (cred, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    createGoogleUser(cred)
      .then((res) => {
        localStorage.setItem("session_token", res.data.token);
        callback();
      })
      .catch((error) => {
        if (error.response)
          dispatch({ type: SET_ERRORS, errors: error.response.data.detail });
      })
      .finally(() => dispatch(setLoading(false)));
  };
};

export const setErrors = (errors) => {
  return (dispatch) => {
    dispatch({ type: SET_ERRORS, errors: errors });
  };
};

export const clearErrors = () => {
  return (dispatch) => {
    dispatch({ type: SET_ERRORS, errors: [] });
  };
};

export const setLoading = (loading) => {
  return (dispatch) => {
    dispatch({ type: SET_LOADING, loading });
  };
};

export const setShowErrors = (showErrors) => {
  return (dispatch) => {
    dispatch({ type: SET_SHOW_ERRORS, showErrors });
  };
};
