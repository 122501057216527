import * as Yup from "yup";
import { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Formik, Form, Field } from "formik";

import { TextField } from "../inputs/textInput";

import { updateRequest } from "../../actions/user";

import { CloseIconButton, UserDialogTitle } from "./styles";

import CloseIcon from "@mui/icons-material/Close";
import { Dialog, Button, DialogContent, DialogActions } from "@mui/material";
import { getSettingsField } from "./userSettings.constants";

export const UserSettings = ({ open, onClose, userData }) => {
  const dispatch = useDispatch();

  const FIELDS_FOR_CHANGE = useMemo(
    () => ({
      email: userData.email,
      first_name: userData.first_name,
      last_name: userData.last_name,
      middle_name: userData.middle_name || "",
      number_of_roundings: userData.number_of_roundings,
      significance_level: userData.significance_level,
      significance_level_normality: userData.significance_level_normality,
    }),
    [userData]
  );

  const [userInfo, setUserInfo] = useState(FIELDS_FOR_CHANGE);

  const handleUserInfo = (event) => {
    const { value, name } = event.target;
    setUserInfo({ ...userInfo, [name]: value });
  };

  const handleCloseSettings = () => {
    onClose(false);
    setUserInfo(FIELDS_FOR_CHANGE);
  };

  const submitUpdates = () => {
    const { middle_name, ...otherData } = userInfo;

    const data = {
      ...otherData,
      ...(middle_name !== "" && middle_name),
    };

    dispatch(updateRequest(data, handleCloseSettings));
  };

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required("Required field"),
    last_name: Yup.string().required("Required field"),
    middle_name: Yup.string(),
    number_of_roundings: Yup.number()
      .required("Required field")
      .positive("Only positive number are aloved"),
    significance_level: Yup.number()
      .required("Required field")
      .positive("Only positive number are aloved"),
    significance_level_normality: Yup.number()
      .required("Required field")
      .positive("Only positive number are aloved"),
  });

  const fields = useMemo(() => getSettingsField(userInfo), [userInfo]);

  return (
    <Dialog open={open} onClose={handleCloseSettings} maxWidth="sm" fullWidth>
      <UserDialogTitle>Settings</UserDialogTitle>
      <CloseIconButton onClick={handleCloseSettings}>
        <CloseIcon />
      </CloseIconButton>

      <Formik
        initialValues={FIELDS_FOR_CHANGE}
        validationSchema={validationSchema}
        onSubmit={submitUpdates}
      >
        {({ setFieldValue, setFieldTouched }) => (
          <Form>
            <DialogContent
              sx={{
                padding: "24px",
                width: "50%",
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                "label+.css-1mmtcjl-MuiInputBase-root": {
                  marginTop: "28px",
                },
                "label+.css-r6pl8p-MuiInputBase-root": {
                  marginTop: "28px",
                },
              }}
            >
              {fields.map(({ name, label, value, type, disabled }, index) => (
                <Field name={name} key={index}>
                  {({ meta }) => (
                    <TextField
                      disabled={disabled}
                      type={type}
                      name={name}
                      label={label}
                      value={value}
                      onBlur={() => setFieldTouched(name, true)}
                      error={!!meta.error && !!meta.touched && meta.error}
                      onChange={(event) => {
                        handleUserInfo(event);
                        setFieldValue(name, event.target.value);
                      }}
                    />
                  )}
                </Field>
              ))}
            </DialogContent>
            <DialogActions
              sx={{
                borderTop: "1px solid #474551",
                padding: "28px 24px 28px",
              }}
            >
              <Button
                variant="contained"
                color="info"
                sx={{ mr: "5px" }}
                onClick={handleCloseSettings}
              >
                Cancel
              </Button>
              <Button variant="contained" color="secondary" type="submit">
                Save
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};
