import { Grid, Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const NavbarWrapper = styled(Grid)(() => ({
  padding: "22px 0 34px 0",
  background: "#041823",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  height: "100vh",
  maxWidth: "242px",
  boxSizing: "border-box",
  width: "100%",
  overflow: "hidden",

  "& img": {
    boxSizing: "border-box",
    padding: "0 26px 12px",
    width: "100%",
    maxWidth: "230px",
  },

  "& ul": {
    padding: 0,
    gap: "6px",
    display: "flex",
    flexDirection: "column",
  },
}));

export const NavbarContent = styled(Box)(() => ({
  borderTop: "1px solid rgba(230, 230, 230, 0.30)",
  padding: "24px 12px",
  width: "100%",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  gap: "40px",
  height: "100%",
}));

export const LinksWrapper = styled(Box)(() => ({
  height: "calc(50vh - 162px)",
  overflowY: "auto",
}));
