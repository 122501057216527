import { handleUserName } from "../../../../../../helpers/getUserName";
import { Linkify } from "../../../../../../helpers/linkify";

import { MessageItem, MessageText } from "../MessageList/styles";

import gptLogo from "../../../../../../images/gpt-avatar.png";

import { ChatAvatar, UserAvatar } from "../AnalyseItem/styled";

export const SimpleMessage = ({ text, error_text, identity, userData }) => {
  return (
    <MessageItem identity={identity === "user"}>
      {identity === "user" ? (
        <UserAvatar>{handleUserName(identity, userData.first_name, userData.last_name)}</UserAvatar>
      ) : (
        <ChatAvatar alt="Chat avatar" src={gptLogo} />
      )}

      {text && (
        <MessageText>
          <Linkify>{text}</Linkify>
        </MessageText>
      )}
      {error_text && <MessageText error_text={!!error_text}>{error_text}</MessageText>}
    </MessageItem>
  );
};
