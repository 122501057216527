import { GOOGLE_FORM_REPORT_LINK } from "../../constants/chat.constants";

import { IconWrapper } from "./styles";

import ReportIcon from "@mui/icons-material/Report";

export const ReportButton = () => {
  return (
    <a href={GOOGLE_FORM_REPORT_LINK} alt="report error link" target="_blank" rel="noreferrer">
      <IconWrapper>
        <ReportIcon />
        Report Error
      </IconWrapper>
    </a>
  );
};
