import { Box, Dialog, DialogContent, Grid, TextField } from "@mui/material";
import { styled } from "@mui/system";

export const Line = styled(Box)(() => ({
  height: "1px",
  backgroundColor: "#c2c8d0",
  width: "100%",
}));

export const LineWrapper = styled(Box)(() => ({
  display: "flex",
  flexWrap: "nowrap",
  gap: "12px",
  alignItems: "center",
  width: "100%",

  p: {
    fontSize: "14px",
  },
}));

export const GreenSpan = styled("span")(() => ({
  color: "#1CB4AE",
  marginLeft: "5px",
  cursor: "pointer",
}));

export const ErrorText = styled(Grid)(() => ({
  position: "absolute",
  fontSize: "13px",
  margin: "2px 0 0 5px",
  color: "#e74c3c",
}));

export const StyledDialogContent = styled(DialogContent)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  gap: "24px",
  padding: "26px",
  color: "#474551",

  ".MuiFormLabel-root": {
    fontSize: "15px",
  },

  ".MuiInputBase-input": {
    padding: "14.5px 14px",
    fontSize: "15px",
    color: "#506176",
  },

  button: {
    width: "100%",
    padding: "12px 16px",
    fontSize: "16px",
  },
}));

export const TextInput = styled(TextField)(() => ({
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#1CB4AE",
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "#1CB4AE",
  },
}));

export const StyledDialog = styled(Dialog)(() => ({
  "& .MuiPaper-root": {
    boxShadow: "none",
  },
}));
