import { useNavigate } from "react-router-dom";

import { HeaderLinks } from "./header.constants";

import GPTLogo from "../../../../images/gpt-logo.png";
import { ListContainer, StyledListItem } from "./styles";

import Grid from "@mui/material/Grid";

export const Header = ({ setOpenLogin, setOpenRegister }) => {
  const navigate = useNavigate();

  const handleUserRedirect = (link) => {
    navigate(link);
  };

  return (
    <Grid
      container
      p="15px 36px 23px 28px"
      flexWrap="nowrap"
      alignItems="center"
      justifyContent="center"
      sx={{
        "& img": {
          boxSizing: "border-box",
          width: "164px",
          mr: 5,
        },
      }}
    >
      <img alt="Logo" src={GPTLogo} />
      <Grid container justifyContent="space-between">
        <ListContainer>
          {HeaderLinks.map(({ title, route }, index) => (
            <StyledListItem onClick={() => handleUserRedirect(route)} key={index}>
              {title}
            </StyledListItem>
          ))}
        </ListContainer>

        <ListContainer>
          <StyledListItem onClick={() => setOpenLogin(true)}>Log in</StyledListItem>
          <StyledListItem onClick={() => setOpenRegister(true)}>Sign up</StyledListItem>
        </ListContainer>
      </Grid>
    </Grid>
  );
};
