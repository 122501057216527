import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { SimpleMenu } from "../simpleMenu/";

import { handleUserName } from "../../helpers/getUserName";

import { ArrowDown } from "../../Icons/ArrowDown";
import {
  UserName,
  // UserRole,
  UserAvatar,
  IconWrapper,
  ContentContainer,
  CustomMenuItem,
} from "./styles";

import Grid from "@mui/material/Grid";
import HomeIcon from "@mui/icons-material/Home";
import LogoutIcon from "@mui/icons-material/Logout";
import SettingsIcon from "@mui/icons-material/Settings";

export const UserInfoBar = ({
  url,
  first_name,
  last_name,
  role,
  setShowSettings,
}) => {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logOut = () => {
    localStorage.setItem("session_token", "");
    navigate("/");
  };

  const goHome = () => {
    navigate("/dashboard");
  };

  const openSettings = () => {
    setShowSettings(true);
  };

  const MENU_ITEMS = [
    {
      title: "Home",
      icon: <HomeIcon />,
      callback: goHome,
      id: 1,
    },
    {
      title: "Settings",
      icon: <SettingsIcon />,
      callback: openSettings,
      id: 2,
    },
    {
      title: "Log out",
      icon: <LogoutIcon />,
      callback: logOut,
      id: 3,
    },
  ];

  return (
    <Grid sx={{ display: "flex" }} alignItems="center" gap="6px">
      {url ? (
        <UserAvatar alt="User avatar" src={url} />
      ) : (
        <UserAvatar alt="User avatar">
          {handleUserName("user", first_name, last_name)}
        </UserAvatar>
      )}
      <ContentContainer>
        <UserName>{`${first_name} ${last_name}`}</UserName>
        {/* <UserRole>{role}</UserRole> */}
      </ContentContainer>
      <IconWrapper onClick={handleClick}>
        <ArrowDown />
      </IconWrapper>

      <SimpleMenu handleClose={handleClose} open={open} anchorEl={anchorEl}>
        {MENU_ITEMS.map(({ title, callback, icon, id }) => (
          <CustomMenuItem onClick={callback} key={id}>
            {icon} {title}
          </CustomMenuItem>
        ))}
      </SimpleMenu>
    </Grid>
  );
};
