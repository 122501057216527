import InputBase from "@mui/material/InputBase";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const Field = styled(InputBase)(({ theme, error }) => ({
  "label + &": {
    marginTop: theme.spacing(4),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    border: error ? "1px solid #d32f2f" : "1px solid #ced4da",
    fontSize: 16,
    width: "100%",
    padding: "10px 12px",
    transition: theme.transitions.create(["border-color", "background-color", "box-shadow"]),
    "&:focus": {
      borderColor: theme.palette.primary.main,
    },
  },
}));

export function TextField({
  disabled = false,
  label,
  name = "",
  value,
  onChange,
  type = "text",
  error = "",
  multilineRows,
  ...props
}) {
  return (
    <FormControl variant="standard" fullWidth error={!!error}>
      <InputLabel
        shrink
        sx={{
          color: "#163B65",
          fontSize: "20px",
        }}
      >
        {label}
      </InputLabel>
      <Field
        disabled={disabled}
        type={type}
        name={name}
        onChange={onChange}
        value={value}
        error={!!error}
        multiline={!!multilineRows}
        rows={multilineRows}
        {...props}
      />
      {!!error ? (
        <Typography variant="error" mt="4px">
          {error}
        </Typography>
      ) : (
        <div style={{ height: "21px" }}></div>
      )}
    </FormControl>
  );
}
